import axios from "axios";
import React, { useState, useEffect, useCallback } from "react";
import Select from "react-select";
import cancelIcon from "../../assets/cancelIcon.svg";

const FunctionsFilter = ({ isOpen, onClose, onApply }) => {
  const [filters, setFilters] = useState({
    function_name: [],
  });

  const [functionOptions, setFunctionOptions] = useState([]);

  const getFunctionOptions = useCallback(() => {
    axios({
      method: "get",
      baseURL: "https://genai-cms-api.nawatech.co/api/sma",
      url: `prompt_function/get-function-name`,
    })
      .then((response) => {
        const { data } = response;
        if (Array.isArray(data)) {
          const options = data.map((option) => ({
            value: option.function_name,
            label: option.function_name,
          }));
          setFunctionOptions(options);
        }
      })
      .catch((error) => {
        console.error("Error fetching capabilities:", error);
      });
  }, []);

  useEffect(() => {
    if (isOpen) {
      getFunctionOptions();
    }
  }, [isOpen, getFunctionOptions]);

  const handleInputChange = (selectedOptions) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      function_name: selectedOptions,
    }));
  };

  const handleClear = () => {
    const clearedFilters = {
      function_name: [],
    };
    setFilters(clearedFilters);
    onApply(clearedFilters);
    onClose();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const appliedFilters = {
      ...filters,
      function_name: filters.function_name.map((option) => option.value),
    };
    onApply(appliedFilters);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="filter-modal-overlay">
      <div className="functions filter-modal">
        <div className="filter-header">
          <h2 className="filter-title">Filter</h2>
          <button className="filter-close" onClick={onClose}>
            <img
              src={cancelIcon}
              alt="Close"
            />
          </button>
        </div>
        <hr className="filter-separator" />
        <form className="filter-form" onSubmit={handleSubmit}>
          <div className="filter-form-group">
            <label htmlFor="filter-name">Function Name</label>
            <Select
              id="filter-name"
              name="function_name"
              isMulti
              options={functionOptions}
              value={filters.function_name}
              onChange={handleInputChange}
              placeholder="-- Select Function Name --"
              className="multi-select"
              classNamePrefix="select"
              closeMenuOnSelect={false}
            />
          </div>
          <div className="filter-form-actions">
            <button
              type="button"
              className="clear-button"
              onClick={handleClear}
            >
              Clear
            </button>
            <button type="submit" className="apply-button">
              Apply
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FunctionsFilter;
