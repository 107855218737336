import chevronLeftMed from "../assets/chevronLeftMed.svg";

const CustomeContainerDatePicker = (children, handleClear, handleClose, onApply, showInput) => {
    return (
        <div className="custom-calendar">
            {showInput
                ? null
                : <>
                    <div className="date-picker-top">
                        <button className="calendar-back-button" onClick={handleClose}>
                            <img
                                src={chevronLeftMed}
                                alt="calendar icon"
                                className="calender-back-icon" />
                        </button>
                        <p>Custome Range</p>
                    </div>
                    <hr />
                </>
            }
            {children}
            <div className="calendar-footer">
                <button className="btn-calendar-clear" onClick={handleClear}>Clear</button>
                <button className="btn-calendar-apply" onClick={onApply}>Apply</button>
            </div>
        </div>
    )
}


export default CustomeContainerDatePicker;