import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import './TokenCostConsumption.css';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const TCCLineChart = () => {
  const sampleData = {
    ttc: {
      day: [85, 90, 88, 92, 95, 97, 94],
      month: [88, 89, 91, 92, 93],
      year: [89, 91, 93, 94, 96],
    },
    ttcc: {
      day: [15, 12, 10, 8, 5, 20, 13],
      month: [12, 11, 10, 9, 7],
      year: [10, 9, 8, 7, 6],
    },
    attc: {
      day: [75, 72, 70, 68, 65, 78, 73],
      month: [72, 71, 70, 69, 73],
      year: [70, 69, 68, 67, 71],
    },
    attcc: {
      day: [4, 4, 4, 4, 4, 4, 4],
      month: [4, 4, 4, 4, 4],
      year: [4, 4, 4, 4, 4],
    }
  };  

  const [selectedMetric, setSelectedMetric] = useState('ttc');
  const [selectedTime, setSelectedTime] = useState('day');

  const handleMetricChange = (e) => setSelectedMetric(e.target.value);
  const handleTimeChange = (e) => setSelectedTime(e.target.value);

  const chartData = {
    labels: selectedTime === 'day' ? ['1', '2', '3', '4', '5', '6', '7'] : selectedTime === 'month' ? ['Jan', 'Feb', 'Mar', 'Apr', 'May'] : ['2020', '2021', '2022', '2023', '2024'],
    datasets: [
      {
        data: sampleData[selectedMetric][selectedTime],
        borderColor: '#ED1C24',
        backgroundColor: '#ED1C241A',
        fill: true
      },
    ],
  };

  const chartOptions = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <div className='rq-line-chart'>
      <div className="rq-dropdown-container">
        <select onChange={handleMetricChange} value={selectedMetric}>
          <option value="ttc">Total Token Consumption</option>
          <option value="ttcc">Total Token Cost Consumption</option>
          <option value="attc">Avg. Total Token Consumption per Chat</option>
          <option value="attcc">Avg. Total Token Cost Consumption per Chat</option>
        </select>
        <select onChange={handleTimeChange} value={selectedTime}>
          <option value="day">Day</option>
          <option value="month">Month</option>
          <option value="year">Year</option>
        </select>
      </div>
      <Line 
        data={chartData} 
        options={chartOptions} 
        height={400} 
        width={600}
      />    
    </div>
  );
};

export default TCCLineChart;
