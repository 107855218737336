import { useCallback, useEffect, useState } from "react";
import loadingIcon from "../../assets/loadingIcon.svg";
import axios from "axios";
import debounce from "../../utils/Debounce";

const FunctionTable = ({ selectedFilters, selectedMonth, selectedYear }) => {

  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const getFunctionData = useCallback(debounce(async (filter, month, year) => {
    setIsLoading(true)
    try {
      const url = `https://genai-cms-api.nawatech.co/api/sma/response-quality/average-response-function`;
      const response = await axios.post(url, {
        year: filter.year,
        psm: filter.psm,
        estate: filter.estate,
        month: filter.month,
        metrics: filter.metrics,
        region: filter.region,
        date_months: month.sort((a, b) => a - b),
        date_years: year.sort((a, b) => a - b)
      });
      if (response.status == 200) {
        const result = response.data;
        setData(result.data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setIsLoading(false)
  }, 2000), [])

  useEffect(() => {
    getFunctionData(selectedFilters, selectedMonth, selectedYear);
  }, [selectedFilters, selectedMonth, selectedYear])

  return (
    <div className="rq-table">
      <table>
        <thead>
          <tr>
            <th>Function</th>
            <th>Response Accuracy (%)</th>
            <th>Average Rating</th>
          </tr>
        </thead>
        <tbody>
          {isLoading
            ? <tr>
              <td colSpan={3} className="text-center">
                <img
                  src={loadingIcon}
                  alt="Loading..."
                />
              </td>
            </tr>
            : data.map((item, index) => (
              <tr key={index}>
                <td>{item.function}</td>
                <td>{item.response_accuracy.rate.toFixed(2)}% ({item.response_accuracy.total} response)</td>
                <td>{item.rating.average_rating.toFixed(2)} ({item.rating.feedback_count} feedback)</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  )
}

export default FunctionTable;