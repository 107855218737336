import React, { useState, useEffect } from "react";
import SuccessAlert from "../SuccessAlert";

const SampleQuestionModal = ({ item, isOpen, onClose }) => {
  const [question, setQuestion] = useState(item?.question || "");
  const [capability, setCapability] = useState("Unknown Capability");
  const [capabilities, setCapabilities] = useState([]);
  const [successNotification, setSuccessNotification] = useState("");

  useEffect(() => {
    const fetchCapabilities = async () => {
      try {
        const response = await fetch(
          "https://genai-cms-api.nawatech.co/api/sma/fallback/get-master-capability/w/not-found"
        );
        const data = await response.json();
        setCapabilities(data.data);
      } catch (error) {
        console.error("Error fetching capabilities:", error);
      }
    };

    if (isOpen && item) {
      fetchCapabilities();
      setQuestion(item?.question || "");
    }
  }, [isOpen, item]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const requestBody = {
      question: question,
      detected_capability: item.detected_capability,
      capability_function: capability,
      doc_id: item.id,
    };

    try {
      const response = await fetch(
        "https://genai-cms-api.nawatech.co/api/sma/fallback/create-sample-question",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (response.ok) {
        console.log("Sample question created successfully.");
        handleUpdateSuccess("Sample question created successfully.");

        // Delay closing the modal to allow the success alert to be visible
        setTimeout(onClose, 2000); // Close modal after 2 seconds
      } else {
        console.error("Failed to create sample question.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleUpdateSuccess = (message) => {
    setSuccessNotification(message);
    setTimeout(() => setSuccessNotification(""), 5000); // Success alert auto-closes after 5 seconds
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        {successNotification && (
          <SuccessAlert
            message={successNotification}
            onClose={() => setSuccessNotification("")}
          />
        )}
        <form onSubmit={handleSubmit} className="modal-form">
          <h2 className="modal-title">Add Sample Question</h2>
          <hr className="modal-separator" />
          <div className="modal-form-group">
            <label htmlFor="question">Question</label>
            <input
              id="question"
              type="text"
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              required
            />
          </div>
          <div className="modal-form-group">
            <label htmlFor="detected_capability">Detected Capability</label>
            <input
              id="detected_capability"
              type="text"
              value={item.detected_capability}
              className="suggested-cap-input"
              readOnly
            />
          </div>
          <div className="modal-form-group">
            <label htmlFor="capability">Capability/Function</label>
            <select
              id="capability"
              value={capability}
              onChange={(e) => setCapability(e.target.value)}
              required
            >
              {capabilities.map((capability, index) => (
                <option key={index} value={capability}>
                  {capability}
                </option>
              ))}
            </select>
          </div>
          <div className="modal-buttons">
            <button type="button" onClick={onClose}>
              Cancel
            </button>
            <button type="submit">Save</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SampleQuestionModal;
