
import TopFunctionChart from "./TopFunctionChart";
import TopMetricsChart from "./TopMetricsChart";
import TopQueries from "./TopQueries";
import TotalFunctionChart from "./TotalFunctionChart";
import TotalInteractionByRoleChart from "./TotalInteractionByRoleChart";
import TotalInteractionChart from "./TotalInteractionChart";
import TotalMetricsChart from "./TotalMetricsChart";
import UserOverview from "./UserOverview";
import calendarIcon from '../../assets/baseCalendarIcon20.svg';

import "react-datepicker/dist/react-datepicker.css";
import "./UserEngagement.css";
import { useRef, useState } from "react";
import FilterModal from "./FilterModal";
import DateModal from "./DateModal";



const UserEngagement = () => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isDateModalOpen, setIsDateModalOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);

  const handleFilterModalClose = () => {
    setIsFilterOpen(false);
  }

  const handleFilterModalOpen = () => {
    setIsFilterOpen(true);
  }

  const handleOpenCloseDateModal = () => {
    if(isDateModalOpen){
      setIsDateModalOpen(false)
    }else{
      setIsDateModalOpen(true)
    }
  }

  const handleDateModalClose = () => {
    setIsDateModalOpen(false)
  }
  return (<>
    <div className="ue-header-container">
      <h3 className="ue-header-title">
        User Engagement
      </h3>
      <div className="group-flex-8">
        <div className="dropdown-calendar">
          <button className="btn-base font-base with-icon" onClick={() => handleOpenCloseDateModal()}>
            <img
              src={calendarIcon}
              alt="calendar icon"
              className="date-button-icon"
            />
            {selectedDate ?? "Choose Date"}
          </button>
          <DateModal isOpen={isDateModalOpen} onClose={handleDateModalClose} onSelectedDate={setSelectedDate} />
        </div>
        <FilterModal isOpen={isFilterOpen} handleOpen={handleFilterModalOpen} handleClose={handleFilterModalClose} />
      </div>
    </div>
    <UserOverview />
    <div className="ue-section-container">
      <TotalInteractionChart />
      <TopQueries />
    </div>
    <TotalFunctionChart />
    <TotalMetricsChart />
    <TotalInteractionByRoleChart />
    <TopFunctionChart />
    <TopMetricsChart />
  </>
  );
}

export default UserEngagement;