import ToolTip from "../../components/ToolTip";
import openModalIcon from "../../assets/shareIcon.svg";
import { useState } from "react";
import ActiveUsers from "./ActiveUsers";
import AvgSessionLength from "./AvgSessionLength";
import RetentionRate from "./RetentionRate";
import ChurnRate from "./ChurnRate";

const UserOverview = () => {

  const [isActiveUserOpen, setIsActiveUserOpen] = useState(false);
  const [isAvgSessionLengthOpen, setIsAvgSessionLengthOpen] = useState(false);
  const [isRetentionRateOpen, setIsRetentionRateOpen] = useState(false);
  const [isChurnRateOpen, setIsChurnRateOpen] = useState(false);

  const handleActiveUserModalOpen = () => {
    setIsActiveUserOpen(true);
  }
  const handleActiveUserModalClose = () => {
    setIsActiveUserOpen(false);
  }

  const handleAvgSessionLengthModalOpen = () => {
    setIsAvgSessionLengthOpen(true);
  }
  const handleAvgSessionLengthModalClose = () => {
    setIsAvgSessionLengthOpen(false);
  }

  const handleRetentionRateModalOpen = () => {
    setIsRetentionRateOpen(true);
  }

  const handleRetentionRateModalClose = () => {
    setIsRetentionRateOpen(false);
  }

  const handleChurnRateModalOpen = () => {
    setIsChurnRateOpen(true);
  }

  const handleChurnRateModalClose = () => {
    setIsChurnRateOpen(false);
  }



  return (
    <div className="ue-section">
      <h3 className="section-title">
        User Overview
      </h3>
      <div className="ue-boxes">
        <div className="ue-box w-280">
          <p className="box-title">
            Total Interactions
            <ToolTip text={'The total number of interactions between users and chatbot based on total messages sent by users and chatbot'} />
          </p>
          <p className="box-value">
            37
          </p>
          <p className="box-date">
            <span className="percentage minus">+10%</span>
            than last month
          </p>
        </div>
        <div className="ue-box w-280">
          <p className="box-title">
            Total Active Users
            <ToolTip text={'The total number of active users interacting with chatbot'} />
            <img src={openModalIcon}
              alt="share icon"
              className="share-icon"
              onClick={() => handleActiveUserModalOpen()}
            />
            <ActiveUsers isOpen={isActiveUserOpen} onClose={handleActiveUserModalClose} />
          </p>
          <p className="box-value">
            20
          </p>
          <p className="box-date">
            <span className="percentage minus">+10%</span>
            than last month
          </p>
        </div>
        <div className="ue-box w-280">
          <p className="box-title">
            Avg. Message/Session
            <ToolTip text={'The average number of messages sent to the chatbot in one session'} />
          </p>
          <p className="box-value">
            10
          </p>
          <p className="box-date">
            <span className="percentage minus">+10%</span>
            than last month
          </p>
        </div>
        <div className="ue-box w-280">
          <p className="box-title">
            Avg. Session Length
            <ToolTip text={'The average duration of user interacting with the chatbot in one session'} />
            <img src={openModalIcon}
              alt="share icon"
              className="share-icon"
              onClick={() => handleAvgSessionLengthModalOpen()}
            />
            <AvgSessionLength isOpen={isAvgSessionLengthOpen} onClose={handleAvgSessionLengthModalClose} />
          </p>
          <p className="box-value">
            30
            <span className="time">min</span>
          </p>
          <p className="box-date">
            <span className="percentage minus">+10%</span>
            than last month
          </p>
        </div>
        <div className="ue-box w-280">
          <p className="box-title">
            Retention Rate
            <ToolTip text={'The rate of users who return to use the chatbot, based on having at least 2 interactions within 1 month of their initial chat/interaction'} />
            <img src={openModalIcon}
              alt="share icon"
              className="share-icon"
              onClick={() => handleRetentionRateModalOpen()}
            />
            <RetentionRate isOpen={isRetentionRateOpen} onClose={handleRetentionRateModalClose} />
          </p>
          <p className="box-value">
            88%
          </p>
          <p className="box-date">
            <span className="percentage minus">+10%</span>
            than last month
          </p>
        </div>
        <div className="ue-box w-280">
          <p className="box-title">
            Churn Rate
            <ToolTip text={'The percentage of users who have not engaged with the chatbot for 1 month or longer'} />
            <img src={openModalIcon}
              alt="share icon"
              className="share-icon"
              onClick={() => handleChurnRateModalOpen()}
            />
            <ChurnRate isOpen={isChurnRateOpen} onClose={handleChurnRateModalClose} />
          </p>
          <p className="box-value">
            12%
          </p>
          <p className="box-date">
            <span className="percentage minus">+10%</span>
            than last month
          </p>
        </div>
      </div>
    </div>
  );
}

export default UserOverview;