import React, { useRef, useState } from "react";
import uploadIcon from "../../assets/whiteUploadIcon20.svg";
import DownloadModal from "./DownloadModal";
import UploadPropertiesModal from "./UploadPropertiesModal";
import GeneralProperties from "./GeneralProperties";
import SpecificProperties from "./SpecificProperties";
import FunctionFilter from "./FunctionFilter";
import DateFilter from "./DateFilter";
import { Alert } from "../../components";

import "./Properties.css";
import { format } from "date-fns";

const Properties = () => {
  const [activeTab, setActiveTab] = useState("General Properties");
  const [isDownloadModalOpen, setDownloadModalOpen] = useState(false);
  const [isUploadModalOpen, setUploadModalOpen] = useState(false);
  const [filters, setFilters] = useState({});

  const alertRef = useRef();

  const showAlert = (data) => {
    alertRef.current.show(data);
  };

  const handleFilterApply = (key, value) => {
    setFilters((prev) => ({ ...prev, [key]: value }));
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleOpenModal = (option) => {
    if (option === "Download") {
      setDownloadModalOpen(true);
    } else if (option === "Upload") {
      setUploadModalOpen(true);
    }
  };

  const handleCloseModal = (option) => {
    if (option === "Download") {
      setDownloadModalOpen(false);
    } else if (option === "Upload") {
      setUploadModalOpen(false);
    }
  };

  const handleUploadSuccess = (data) => {
    const { code, message, detail } = data;

    if (code !== 200) {
      showAlert({ type: "error", title: code, message: detail });
      return;
    }

    showAlert({ type: "success", message: message });
  };

  const handleUploadError = (error) => {
    const { message, response: { data: { detail } = {} } = {} } = error;

    showAlert({
      type: "error",
      title: message,
      message: detail,
    });
  };

  return (
    <>
      <Alert ref={alertRef} />

      <div className="functions-container">
        <div className="functions-header">
          <h1>Properties</h1>
          <button
            className="btn-primary with-icon font-primary"
            onClick={() => handleOpenModal("Upload")}
          >
            <img src={uploadIcon} className="upload-icon" alt="upload icon" />
            Upload Bulk Properties
          </button>
        </div>
        <div className="functions-buttons">
          <div className="props-toggle-buttons">
            <button
              className={`props-toggle-button ${activeTab === "General Properties" ? "active" : ""
                }`}
              onClick={() => handleTabClick("General Properties")}
            >
              General Properties
            </button>
            <button
              className={`props-toggle-button ${activeTab === "Specific Properties" ? "active" : ""
                }`}
              onClick={() => handleTabClick("Specific Properties")}
            >
              Specific Properties
            </button>
          </div>

          {/* New container for dropdown and button */}
          <div className="functions-right-container">
            {activeTab === "Specific Properties" && (
              <>
                <FunctionFilter
                  onApply={(option) =>
                    handleFilterApply("function_id", option.value)
                  }
                />
              </>
            )}
            <button
              className="btn-base font-base"
              onClick={() => handleOpenModal("Download")}
            >
              Download Properties
            </button>
          </div>
        </div>
        {activeTab === "General Properties" ? (
          <GeneralProperties />
        ) : (
          <SpecificProperties filters={filters} />
        )}
        <DownloadModal
          filters={filters}
          isOpen={isDownloadModalOpen}
          onClose={() => handleCloseModal("Download")}
        />
        <UploadPropertiesModal
          // isOpen={isUploadModalOpen}
          // onClose={() => handleCloseModal("Upload")}
          // onSuccess={handleUploadSuccess}

          isOpen={isUploadModalOpen}
          onClose={() => handleCloseModal("Upload")}
          onUploaded={handleUploadSuccess}
          onError={handleUploadError}
        />
      </div>
    </>
  );
};

export default Properties;
