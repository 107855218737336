import React, { useCallback, useEffect, useState } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from 'chart.js';
import './ResponseQuality.css';
import axios from 'axios';
import debounce from '../../utils/Debounce';

ChartJS.register(CategoryScale, LinearScale, PointElement, BarElement, Title, Tooltip, Legend);

const RQLineChart = ({ selectedFilters, selectedMonth, selectedYear }) => {
  const [selectedMetric, setSelectedMetric] = useState('success_rate');
  const [selectedTime, setSelectedTime] = useState('day');
  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isSelectDayDisabled, setIsSelectDayDisabled] = useState(false)

  const listMetric = [
    { success_rate: "Success Rate (%)" },
    { fallback_rate: "Fallback Rate (%)" },
    { satisfaction_score: "Satisfaction Score (%)" },
    { user_rating: "User Rating (%)" },
    { response_time: "Response Time (%)" },
  ]

  const handleMetricChange = (e) => setSelectedMetric(e.target.value);
  const handleTimeChange = (e) => setSelectedTime(e.target.value);

  const getData = useCallback(debounce(async (filter, month, year, metric, time) => {
    setIsLoading(true)
    try {
      const url = `https://genai-cms-api.nawatech.co/api/sma/response-quality/line-chart`;
      const response = await axios.post(url, {
        year: filter.year,
        psm: filter.psm,
        estate: filter.estate,
        month: filter.month,
        metrics: filter.metrics,
        region: filter.region,
        date_months: month.sort((a, b) => a - b),
        date_years: year.sort((a, b) => a - b),
        line_type: metric,
        period_type: time
      });
      if (response.status == 200) {
        const result = await response.data;
        setData(result.data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setIsLoading(false)
  }, 2000), [])

  useEffect(() => {
    getData(selectedFilters, selectedMonth, selectedYear, selectedMetric, selectedTime);
  }, [selectedFilters, selectedMonth, selectedYear, selectedMetric, selectedTime])

  useEffect(() => {
    if (selectedMonth.length > 1 || selectedYear.length > 1) {
      setIsSelectDayDisabled(true)
      setSelectedTime('month')
    } else {
      setIsSelectDayDisabled(false)
    }
  }, [selectedMonth, selectedYear])

  const chartData = {
    labels: data.map((x) => x.date),
    datasets: [
      {
        label: listMetric[selectedMetric],
        data: data.map((x) => x.total),
        borderColor: 'white',
        backgroundColor: '#71AEEB',
        borderWidth: 2,
        maxBarThickness: 70
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      }
    },
  };

  return (
    <div className='rq-line-chart'>
      <div className="rq-dropdown-container">
        <select onChange={handleMetricChange} value={selectedMetric}>
          <option value="success_rate" defaultChecked={true}>Success Rate</option>
          <option value="fallback_rate">Error/Fallback Rate</option>
          <option value="satisfaction_score">Satisfaction Score</option>
          <option value="user_rating">Avg. User Rating</option>
          <option value="response_time">Avg. Response Time</option>
        </select>
        <select onChange={handleTimeChange} value={selectedTime}>
          <option value="day" defaultChecked={true} disabled={isSelectDayDisabled}>Day</option>
          <option value="month">Month</option>
          <option value="year">Year</option>
        </select>
      </div>
      <Bar
        data={chartData}
        options={chartOptions}
        style={{ height: '300px' }}
      />
    </div>
  );
};

export default RQLineChart;
