import { useState } from "react";
import filterIcon from "../../assets/baseFilterIcon20.svg";
import Select from "react-select";
import cancelIcon from "../../assets/cancelIcon.svg";


const FilterModal = ({ isOpen, onApply, handleOpen, handleClose }) => {
  const [filters, setFilters] = useState({});

  const options = [
    { value: "option_1", label: "Option 1" },
    { value: "option_2", label: "Option 2" },
    { value: "option_3", label: "Option 3" },
  ];

  const handleInputChange = (key, selectedOptions) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: selectedOptions,
    }));
  };

  const handleClear = () => {
    setFilters({});
    // onApply({});
    handleClose();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const appliedFilters = {
      ...filters,
      dashboard_type: filters.dashboard_type?.value,
      psm: filters.psm?.value,
      region: filters.region?.value,
      estate: filters.estate?.value,
      division: filters.division?.value,
      complex: filters.complex?.value,
      block: filters.block?.value,
      role: filters.role?.value
    };
    onApply(appliedFilters);
    handleClose();
  };
  return (
    <div className="filter-container">
      <button className="btn-base font-base with-icon"
        onClick={handleOpen}>
        <img
          src={filterIcon}
          alt="calendar icon"
          className="filter-button-icon"
        />
        Filter
      </button>
      {!isOpen ? null : (
        <div className="filter-modal-overlay">
          <div className="rq filter-modal">
            <div className="filter-header">
              <h2 className="filter-title">Filter</h2>
              <button className="filter-close" onClick={handleClose}>
                <img
                  src={cancelIcon}
                  alt="Close"
                />
              </button>
            </div>
            <hr className="analytics-filter-separator" />
            <form className="filter-form" onSubmit={handleSubmit}>
              <div className="filter-form-row">
                <div className="filter-form-group">
                  <label htmlFor="filter-name">Role</label>
                  <Select
                    id="role"
                    name="role"
                    isMulti
                    options={options}
                    value={filters.role}
                    onChange={handleInputChange}
                    placeholder="-- Select Rol --"
                    className="multi-select"
                    classNamePrefix="select"
                    closeMenuOnSelect={false}
                  />
                </div>
                <div className="filter-form-group">
                  <label htmlFor="filter-name">Dashboard Type</label>
                  <Select
                    id="dashboard_type"
                    name="dashboard_type"
                    isMulti
                    options={options}
                    value={filters.dashboard_type}
                    onChange={handleInputChange}
                    placeholder="-- Select Dashboard Type --"
                    className="multi-select"
                    classNamePrefix="select"
                    closeMenuOnSelect={false}
                  />
                </div>
              </div>
              <div className="filter-form-row">
                <div className="filter-form-group">
                  <label htmlFor="filter-name">PSM</label>
                  <Select
                    id="psm"
                    name="psm"
                    isMulti
                    options={options}
                    value={filters.psm}
                    onChange={handleInputChange}
                    placeholder="-- Select PSM --"
                    className="multi-select"
                    classNamePrefix="select"
                    closeMenuOnSelect={false}
                  />
                </div>
                <div className="filter-form-group">
                  <label htmlFor="filter-name">Region</label>
                  <Select
                    id="region"
                    name="region"
                    isMulti
                    options={options}
                    value={filters.region}
                    onChange={handleInputChange}
                    placeholder="-- Select Region --"
                    className="multi-select"
                    classNamePrefix="select"
                    closeMenuOnSelect={false}
                  />
                </div>
              </div>
              <div className="filter-form-row">
                <div className="filter-form-group">
                  <label htmlFor="filter-name">Estate</label>
                  <Select
                    id="estate"
                    name="estate"
                    isMulti
                    options={options}
                    value={filters.estate}
                    onChange={handleInputChange}
                    placeholder="-- Select Estate --"
                    className="multi-select"
                    classNamePrefix="select"
                    closeMenuOnSelect={false}
                  />
                </div>
                <div className="filter-form-group">
                  <label htmlFor="filter-name">Division</label>
                  <Select
                    id="division"
                    name="division"
                    isMulti
                    options={options}
                    value={filters.division}
                    onChange={handleInputChange}
                    placeholder="-- Select Division --"
                    className="multi-select"
                    classNamePrefix="select"
                    closeMenuOnSelect={false}
                  />
                </div>
              </div>
              <div className="filter-form-row">
                <div className="filter-form-group">
                  <label htmlFor="filter-name">Complex</label>
                  <Select
                    id="complex"
                    name="complex"
                    isMulti
                    options={options}
                    value={filters.complex}
                    onChange={handleInputChange}
                    placeholder="-- Select Complex --"
                    className="multi-select"
                    classNamePrefix="select"
                    closeMenuOnSelect={false}
                  />
                </div>
                <div className="filter-form-group">
                  <label htmlFor="filter-name">Block</label>
                  <Select
                    id="block"
                    name="block"
                    isMulti
                    options={options}
                    value={filters.block}
                    onChange={handleInputChange}
                    placeholder="-- Select Block --"
                    className="multi-select"
                    classNamePrefix="select"
                    closeMenuOnSelect={false}
                  />
                </div>
              </div>
              <div className="filter-form-actions">
                <button
                  type="button"
                  className="clear-button"
                  onClick={handleClear}
                >
                  Clear
                </button>
                <button type="submit" className="apply-button">
                  Apply
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

    </div>
  )
}

export default FilterModal;