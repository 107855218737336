import CustomeContainerDatePicker from "./CustomeContainerDatePicker";
import CustomeHeaderDatePicker from "./CustomeHeaderDatePicker";
import DatePicker from "react-datepicker";
import CustomInputDatePicker from "./CustomInputDatePicker";
import CustomInputRangeDatePicker from "./CustomInputRangeDatePicker";

const CustomDatePicker = ({ startDate, endDate, onChange, onApply, onClear, onClose, dateRef, showInput }) => {
  return (<>
    <DatePicker
      ref={dateRef}
      selectsRange
      selected={startDate}
      startDate={startDate}
      endDate={endDate}
      onChange={onChange}
      customInput={showInput ? <CustomInputDatePicker /> : <CustomInputRangeDatePicker />}
      renderCustomHeader={({
        date,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => CustomeHeaderDatePicker(date, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled)}
      calendarContainer={({ children }) => CustomeContainerDatePicker(children, onClear, onClose, onApply, showInput)}
    />
  </>)
}

export default CustomDatePicker;