import React, { useEffect, useRef, useState } from 'react';
import CustomDatePicker from '../../components/CustomDatePicker';
import DatePicker from 'react-datepicker';
import { format, getYear, set } from 'date-fns';

const TCCDateModal = ({ isOpen, onClose, selectedMonth, selectedYear, setSelectedMonth, setSelectedYear, currentMonth, currentYear }) => {
  const [selectedMonthAll, setSelectedMonthAll] = useState(false)
  const [selectedYearAll, setSelectedYearAll] = useState(false)

  const months = [
    { title: "January", value: 1 },
    { title: "February", value: 2 },
    { title: "March", value: 3 },
    { title: "April", value: 4 },
    { title: "May", value: 5 },
    { title: "June", value: 6 },
    { title: "July", value: 7 },
    { title: "August", value: 8 },
    { title: "September", value: 9 },
    { title: "October", value: 10 },
    { title: "November", value: 11 },
    { title: "December", value: 12 }
  ];

  const years = [];
  for (let year = 2023; year <= currentYear; year++) {
    years.push(year);
  }

  const handleSelectMonth = (value) => {
    if (selectedMonthAll) setSelectedMonthAll(false)
    if (!selectedMonth.includes(value)) {
      setSelectedMonth([...selectedMonth, value]);
    } else {
      setSelectedMonth(selectedMonth.filter((item) => item !== value));
    }
  }

  const handleSelectMonthAll = (e) => {
    if (e.target.checked) {
      setSelectedMonth(months.map(month => month.value))
      setSelectedMonthAll(true)
    } else {
      setSelectedMonth([currentMonth])
      setSelectedMonthAll(false)
    }
  }

  const handleSelectYear = (value) => {
    if (selectedYearAll) setSelectedYearAll(false)
    if (!selectedYear.includes(value)) {
      setSelectedYear([...selectedYear, value]);
    } else {
      setSelectedYear(selectedYear.filter((item) => item !== value));
    }
  }

  const handleSelectYearAll = (e) => {
    if (e.target.checked) {
      setSelectedYear(years.map(year => year))
      setSelectedYearAll(true)
    } else {
      setSelectedYear([currentYear])
      setSelectedYearAll(false)
    }
  }

  const handleDateApply = () => {
    //Hit API here;
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="date-modal">
      <div className="date-modal-content large">
        <div className="group-flex-16">
          <ul>
            <li>
              <label className='form-checkbox'>
                <input type='checkbox' checked={selectedMonthAll} onChange={(e) => handleSelectMonthAll(e)} />
                <span className='checkmark'></span>
                All
              </label>
            </li>
            {months.map((month) => (
              <li key={month.value}>
                <label className='form-checkbox cursor-pointer'>
                  <input type='checkbox'
                    id={`${month.title}-checkbox`}
                    checked={selectedMonth.includes(month.value)}
                    onChange={() => handleSelectMonth(month.value)}
                    disabled={selectedMonth.includes(month.value) && selectedMonth.length == 1} />
                  <span className='checkmark'></span>
                  {month.title}
                </label>
              </li>
            ))}
          </ul>
          <div className="separator-line"></div>
          <ul>
            <li>
              <label className='form-checkbox'>
                <input type='checkbox' checked={selectedYearAll} onChange={(e) => handleSelectYearAll(e)} />
                <span className='checkmark'></span>
                All
              </label>
            </li>
            {years.map((year) => (
              <li key={year}>
                <label className='form-checkbox'>
                  <input type='checkbox' id={`${year}-checkbox`} checked={selectedYear.includes(year)} onChange={() => handleSelectYear(year)} disabled={selectedYear.includes(year) && selectedYear.length == 1} />
                  <span className='checkmark'></span>
                  {year}
                </label>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default TCCDateModal;
