import React, { useState, useEffect, useRef } from "react";
import filterIcon from "../assets/baseFilterIcon20.svg";
import exportIcon from "../assets/whiteExportIcon20.svg";
import Analytics from "./Analytics";
import Overview from "./Overview";
import FilterModal from "./FilterModal";
import AnalyticsFilterModal from "./AnalyticsFilterModal";
import DatePicker from "react-datepicker";
import { format } from 'date-fns';
import "react-datepicker/dist/react-datepicker.css";
import "./FallbackManagement.css";
import CustomDatePicker from "../components/CustomDatePicker";

const FallbackManagement = () => {
  const [activeTab, setActiveTab] = useState("Overview");
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [isAnalyticsFilterModalOpen, setIsAnalyticsFilterModalOpen] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [overviewCurrentPage, setOverviewCurrentPage] = useState(1);

  const dateRef = useRef(<DatePicker />);

  const [selectedFilters, setSelectedFilters] = useState({
    detectedCapability: null,
    selectedCapability: null
  });
  const [selectedAnalyticsFilters, setSelectedAnalyticsFilters] = useState({
    detectedCapability: null,
    month: null,
    year: null,
    metrics: null,
    psm: null,
    region: null,
    estate: null,
  });

  useEffect(() => {
  }, [startDate, endDate, selectedFilters, selectedAnalyticsFilters]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleCloseModal = () => {
    if (activeTab === 'Overview') {
      setIsFilterModalOpen(false);
    } else if (activeTab === 'Analytics') {
      setIsAnalyticsFilterModalOpen(false);
    }
  };

  const handleFilterButtonClick = () => {
    if (activeTab === 'Overview') {
      setIsFilterModalOpen(!isFilterModalOpen);
    } else if (activeTab === 'Analytics') {
      setIsAnalyticsFilterModalOpen(!isAnalyticsFilterModalOpen);
    }
  };

  const handleDateApply = () => {
    //Hit API here;
    handleDatePickerClose();
  };

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    setOverviewCurrentPage(1);
  };

  const handleDatePickerClose = () => {
    dateRef.current.setOpen(false);
  }

  const handleDatePickerOpen = () => {
    dateRef.current.setOpen(true);
  }

  const handleDatePickerClear = () => {
    setStartDate(null);
    setEndDate(null);
    setOverviewCurrentPage(1);
  };

  const handleFilterApply = (filters) => {
    setSelectedFilters(filters);
    setOverviewCurrentPage(1);
    handleCloseModal();
  };

  const handleAnalyticsFilterApply = (filters) => {
    setSelectedAnalyticsFilters(filters);
    handleCloseModal();
  };

  const handleExportData = async () => {
    try {
      const formattedStartDate = startDate ? format(startDate, 'yyyy-MM-dd') : null;
      const formattedEndDate = endDate ? format(endDate, 'yyyy-MM-dd') : null;

      const url = activeTab === 'Overview'
        ? `https://genai-cms-api.nawatech.co/api/sma/fallback/get-data-gpt?capability=${selectedFilters.detectedCapability}&suggested_capability=${selectedFilters.selectedCapability}&start_time=${formattedStartDate}&end_time=${formattedEndDate}&page=1&size=100&is_export=true`
        : `https://genai-cms-api.nawatech.co/api/sma/fallback/get-analytic-gpt?detected_capability=${selectedAnalyticsFilters.detectedCapability}&year=${selectedAnalyticsFilters.year}&psm=${selectedAnalyticsFilters.psm}&estate=${selectedAnalyticsFilters.estate}&month=${selectedAnalyticsFilters.month}&metrics=${selectedAnalyticsFilters.metrics}&region=${selectedAnalyticsFilters.region}&page=1&size=100&is_export=true`;
  
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const contentDisposition = response.headers.get('content-disposition');
      let filename = 'fallback_management.xlsx';

      if (contentDisposition && contentDisposition.includes('attachment')) {
        const filenameMatch = contentDisposition.match(/filename="(.+)"/);
        if (filenameMatch && filenameMatch.length === 2) {
          filename = filenameMatch[1];
        }
      }

      const blob = await response.blob();

      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = filename;

      document.body.appendChild(link);
      link.click();

      link.remove();
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error('Error exporting data:', error);
    }
  };

  const renderContent = () => {
    switch (activeTab) {
      case 'Overview':
        return <Overview startDate={startDate} endDate={endDate} currentPage={overviewCurrentPage} setCurrentPage={setOverviewCurrentPage} filters={selectedFilters} />;
      case 'Analytics':
        return <Analytics filters={selectedAnalyticsFilters} />;
      default:
        return null;
    }
  };

  return (
    <div className="fallback-management">
      <h1 className="page-title">Fallback Management</h1>
      <div className="top-controls">
        <div className="toggle-buttons">
          <button
            className={`toggle-button ${activeTab === "Overview" ? "active" : ""}`}
            onClick={() => handleTabClick("Overview")}
          >
            Overview
          </button>
          <button
            className={`toggle-button ${activeTab === "Analytics" ? "active" : ""}`}
            onClick={() => handleTabClick("Analytics")}
          >
            Analytics
          </button>
        </div>
        <div className="action-buttons">
          {activeTab === "Overview" && (
            <>
              <CustomDatePicker
                startDate={startDate}
                endDate={endDate} onChange={handleDateChange}
                onApply={handleDateApply}
                onClear={handleDatePickerClear}
                onClose={handleDatePickerClose}
                dateRef={dateRef}
                showInput={true} />
              <button className="btn-base with-icon-r font-base" onClick={handleFilterButtonClick}>
                Filter
                <img src={filterIcon} className="filter-icon" alt="filter icon" />
              </button>
              <button className="btn-primary with-icon font-primary" onClick={handleExportData}>
                <img src={exportIcon} className="export-icon" alt="export icon" />
                Export Data
              </button>
            </>
          )}
          {activeTab === "Analytics" && (
            <>
              <button className="btn-base with-icon-r font-base" onClick={handleFilterButtonClick}>
                Filter
                <img src={filterIcon} className="filter-icon" alt="filter icon" />
              </button>
            </>
          )}
        </div>
      </div>
      <div >{renderContent()}</div>
      <FilterModal isOpen={isFilterModalOpen} onClose={handleCloseModal} onApply={handleFilterApply} />
      <AnalyticsFilterModal isOpen={isAnalyticsFilterModalOpen} onClose={handleCloseModal} onApply={handleAnalyticsFilterApply} />
    </div>
  );
};

export default FallbackManagement;
