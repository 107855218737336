import React from "react";
import calendarIcon from "../assets/baseCalendarIcon20.svg";
import { getDate, getMonth, getYear } from "date-fns";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const getStartDate = (value) => {
  const slicedDate = value.slice(0, 10);
  const [day, month, year] = splitDate(slicedDate);
  return `${day} ${month} ${year}`;
}

const getEndDate = (value) => {
  const slicedDate = value.slice(13);
  if (!slicedDate) return null;
  const [day, month, year] = splitDate(slicedDate);
  return `${day} ${month} ${year}`;
}

const splitDate = (slicedDate) => {
  const year = getYear(slicedDate);
  const month = months[getMonth(slicedDate)];
  const day = getDate(slicedDate);
  return [day, month, year];
}

const CustomInputDatePicker = React.forwardRef(({ value, onClick}, ref) => (
  <button className="btn-base font-base with-icon" onClick={onClick} ref={ref}>
    <img src={calendarIcon} alt="calendar icon" className="date-button-icon" />
    {value ? <span className="font-btn-base">{getStartDate(value)} To {getEndDate(value)}</span> : <span className="font-btn-base">Choose Date</span>}
  </button>
));

export default CustomInputDatePicker;