import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Tooltip,
  LineController,
  BarController,
} from 'chart.js';
import { useState } from 'react';

import { Chart } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, LineController, BarController, PointElement, LineElement, Tooltip);

const TotalInteractionChart = () => {
  const [selectedMetric, setSelectedMetric] = useState('totalInteractions');
  const [selectedTime, setSelectedTime] = useState('day');

  const handleMetricChange = (e) => setSelectedMetric(e.target.value);
  const handleTimeChange = (e) => setSelectedTime(e.target.value)

  const sampleData = {
    totalInteractions: {
      day: [85, 90, 88, 92, 95, 97, 94],
      month: [88, 89, 91, 92, 93],
      year: [89, 91, 93, 94, 96],
    },
    totalActiveUsers: {
      day: [15, 12, 10, 8, 5, 20, 13],
      month: [12, 11, 10, 9, 7],
      year: [10, 9, 8, 7, 6],
    },
    avgSessionLength: {
      day: [75, 72, 70, 68, 65, 78, 73],
      month: [72, 71, 70, 69, 73],
      year: [70, 69, 68, 67, 71],
    },
    avgMessageSession: {
      day: [4, 4, 4, 4, 4, 4, 4],
      month: [4, 4, 4, 4, 4],
      year: [4, 4, 4, 4, 4],
    },
    retentionRate: {
      day: [15, 12, 10, 8, 5, 20, 13],
      month: [12, 11, 10, 9, 7],
      year: [10, 9, 8, 7, 6],
    },
    churnRate: {
      day: [15, 12, 10, 8, 5, 20, 13],
      month: [12, 11, 10, 9, 7],
      year: [10, 9, 8, 7, 6],
    },
  };

  const sampleLabels = {
    day: ['1', '2', '3', '4', '5', '6', '7'],
    month: ['Jan', 'Feb', 'Mar', 'Apr', 'May'],
    year: ['2020', '2021', '2022', '2023', '2024'],
  }

  const data = {
    labels: sampleLabels[selectedTime],
    datasets: [
      {
        type: 'line',
        borderColor: 'rgb(255, 99, 132)',
        borderWidth: 2,
        fill: false,
        data: sampleData[selectedMetric][selectedTime],
      },
      {
        type: 'bar',
        backgroundColor: 'rgb(113, 174, 235)',
        data: sampleData[selectedMetric][selectedTime],
        borderColor: 'white',
        borderWidth: 2,
        maxBarThickness: 70
      },
    ]
  }

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <div className='ue-section grow'>
      <div className='ue-filter-container'>
        <select onChange={handleMetricChange}
          value={selectedMetric}
          className='ue-select-form'>
          <option value="totalInteractions">Total Interactions</option>
          <option value="totalActiveUsers">Total Active Users</option>
          <option value="avgSessionLength">Avg Session Length</option>
          <option value="avgMessageSession">Avg Message/Session</option>
          <option value="retentionRate">Retention Rate</option>
          <option value="churnRate">Churn Rate</option>
        </select>
        <select onChange={handleTimeChange}
          value={selectedTime}
          className='ue-select-form'>
          <option value="day">Day</option>
          <option value="month">Month</option>
          <option value="year">Year</option>
        </select>
      </div>
      <div>
        <Chart type='bar' data={data} options={chartOptions} style={{ height: '300px' }} />
      </div>
      <div className='chart-legend-container'>
        <div className='chart-legend'>
          <div className='legend-blue'></div>
          <p className='legend-title'>
            Total Interactions
          </p>
        </div>
        <div className='chart-legend'>
          <div className='legend-red'></div>
          <p className='legend-title'>
            Avg. Interactions
          </p>
        </div>
      </div>
    </div>)
}

export default TotalInteractionChart;