import React, { useState } from "react";
import cancelIcon from "../../assets/cancelIcon.svg";
import dropdownIcon from "../../assets/dropdownIcon.svg";
import backArrow from "../../assets/backArrow.svg";
import { ReactComponent as DetailsIcon } from "../../assets/detailsEye.svg";
import kebabMenu from "../../assets/kebabMenu.svg";
import "./ResponseQuality.css";

const UserSatisfaction = ({ isOpen, onClose }) => {
  const detailsList = [
    {
      question: "berikan ffb production 2029 di PSM-3",
      botAnswer: "Lorem ipsum dolor sit amet consectetur. Pretium sapien eu justo mattis. Lacus dignissim velit vulputate et sit blandit.",
      relatedFunction: "MetricsSummary",
      userSatisfaction: "Thumbs Up",
      timestamp: "2024-07-25 10:55",
    },
    {
      question: "berikan ffb production 2029 di PSM-3",
      botAnswer: "Lorem ipsum dolor sit amet consectetur. Pretium sapien eu justo mattis. Lacus dignissim velit vulputate et sit blandit.",
      relatedFunction: "MetricsSummary",
      userSatisfaction: "Thumbs Up",
      timestamp: "2024-07-25 10:55",
    }, 
    {
      question: "berikan ffb production 2029 di PSM-3",
      botAnswer: "Lorem ipsum dolor sit amet consectetur. Pretium sapien eu justo mattis. Lacus dignissim velit vulputate et sit blandit.",
      relatedFunction: "MetricsSummary",
      userSatisfaction: "Thumbs Up",
      timestamp: "2024-07-25 10:55",
    }
  ]

  const feedbacks = [
    {
      email: "username@mail.com",
      timestamp: "2024-07-25 10:55",
      reason: "Akurat",
      comment:
        "Lorem ipsum dolor sit amet consectetur. Nibh ac nisl quis magna. Habitant semper malesuada leo libero. Nulla interdum placerat ultrices aenean ut.",
      details: detailsList
    },
    {
      email: "username@mail.com",
      timestamp: "2024-07-25 10:55",
      reason: "Konsisten",
      comment:
        "Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur. Vestibulum ornare ac diam sem elementum neque.",
      details: detailsList
    },
    {
      email: "username@mail.com",
      timestamp: "2024-07-25 10:55",
      reason: "Konsisten",
      comment:
        "Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur. Vestibulum ornare ac diam sem elementum neque.",
      details: detailsList
    },
    {
      email: "username@mail.com",
      timestamp: "2024-07-25 10:55",
      reason: "Konsisten",
      comment:
        "Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur. Vestibulum ornare ac diam sem elementum neque.",
      details: detailsList
    },
    {
      email: "username@mail.com",
      timestamp: "2024-07-25 10:55",
      reason: "Konsisten",
      comment:
        "Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur. Vestibulum ornare ac diam sem elementum neque.",
      details: detailsList
    },
    {
      email: "username@mail.com",
      timestamp: "2024-07-25 10:55",
      reason: "Konsisten",
      comment:
        "Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur. Vestibulum ornare ac diam sem elementum neque.",
      details: detailsList
    },
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const [activeKebabIndex, setActiveKebabIndex] = useState(null);
  const [viewingDetails, setViewingDetails] = useState(false);
  const [selectedFeedback, setSelectedFeedback] = useState(null);

  const rowsPerPage = 3;
  const indexOfLastFeedback = currentPage * rowsPerPage;
  const indexOfFirstFeedback = indexOfLastFeedback - rowsPerPage;
  const currentFeedbacks = feedbacks.slice(
    indexOfFirstFeedback,
    indexOfFirstFeedback + rowsPerPage
  );

  const paginate = (direction) => {
    if (
      direction === "next" &&
      currentPage < Math.ceil(feedbacks.length / rowsPerPage)
    ) {
      setCurrentPage(currentPage + 1);
    } else if (direction === "prev" && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const toggleKebabMenu = (index) => {
    setActiveKebabIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleViewDetails = (feedback) => {
    setSelectedFeedback(feedback);
    setViewingDetails(true);
  };

  const handleBackToList = () => {
    setViewingDetails(false);
  };

  if (!isOpen) return null;

  return (
    <div className={`user-rating ${isOpen ? "open" : ""}`}>
      <div className="user-rating-content">
        <div className="user-rating-header">
          {viewingDetails ? (
            <button className="rating-close-button" onClick={handleBackToList}>
              <img src={backArrow} alt="back arrow" />
            </button>
          ) : (
            <></>
          )}
          <h1 className="user-rating-title">User Satisfaction</h1>
          <button className="rating-close-button" onClick={onClose}>
            <img src={cancelIcon} alt="cancel icon" />
          </button>
        </div>
        <div className="rating-separator" />

        {viewingDetails && selectedFeedback ? (
          <div className="user-satisfaction-details">
            <h2>Conversation History</h2>
            <div className="feedback-section">
              <table className="feedback-table">
                <thead>
                  <tr>
                    <th>Question</th>
                    <th>Bot Answer</th>
                    <th>Related Function</th>
                    <th>User Satisfaction</th>
                    <th>Timestamp</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedFeedback.details.map((feedback, index) => (
                    <tr key={index}>
                      <td>{feedback.question}</td>
                      <td>{feedback.botAnswer}</td>
                      <td>{feedback.relatedFunction}</td>
                      <td>{feedback.userSatisfaction}</td>
                      <td>{feedback.timestamp}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="user-rating-pagination">
                <button
                  onClick={() => paginate("prev")}
                  disabled={currentPage === 1}
                >
                  Previous
                </button>
                <button
                  onClick={() => paginate("next")}
                  disabled={
                    currentPage === Math.ceil(feedbacks.length / rowsPerPage)
                  }
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div className="satisfaction-filter">
              <button className="satisfaction-button">
                ALL <img src={dropdownIcon} alt="dropdown" />
              </button>
            </div>
            <div className="feedback-section">
              <table className="feedback-table">
                <thead>
                  <tr>
                    <th>User Satisfaction</th>
                    <th>Bot Answer</th>
                    <th>Related Function</th>
                    <th> </th>
                  </tr>
                </thead>
                <tbody>
                  {currentFeedbacks.map((feedback, index) => (
                    <tr key={index}>
                      <td>{feedback.email}</td>
                      <td>{feedback.timestamp}</td>
                      <td>{feedback.reason}</td>
                      <td>
                        <div className="kebab-menu-container">
                          <img
                            src={kebabMenu}
                            alt="kebab menu"
                            onClick={() => toggleKebabMenu(index)}
                          />
                          {activeKebabIndex === index && (
                            <div className="kebab-menu">
                              <div
                                className="kebab-menu-option"
                                onClick={() => handleViewDetails(feedback)}
                              >
                                <DetailsIcon className="kebab-menu-icon" />
                                View Details
                              </div>
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="user-rating-pagination">
                <button
                  onClick={() => paginate("prev")}
                  disabled={currentPage === 1}
                >
                  Previous
                </button>
                <button
                  onClick={() => paginate("next")}
                  disabled={
                    currentPage === Math.ceil(feedbacks.length / rowsPerPage)
                  }
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserSatisfaction;
