import ToolTip from "../../components/ToolTip";

const TopQueries = () => {
  return (
    <div className="ue-section w-400">
      <h3 className="section-title">
        Top Queries
        <ToolTip text={"Most frequently queried Metric, Location, Month and  Year by users"} />
      </h3>
      <div className="ue-boxes-grid">
        <div className="ue-box">
          <p className="box-title">
            Top Metric Queried
          </p>
          <p className="box-value-sm">
            FFB Production
          </p>
          <p className="box-date">
            5/20
          </p>
        </div>
        <div className="ue-box">
        <p className="box-title">
            Top Location
          </p>
          <p className="box-value-sm">
            PSM-2
          </p>
          <p className="box-date">
            5/20
          </p>
        </div>
        <div className="ue-box">
        <p className="box-title">
            Top Month Queried
          </p>
          <p className="box-value-sm">
            January
          </p>
          <p className="box-date">
            3/20
          </p>
        </div>
        <div className="ue-box">
        <p className="box-title">
            Top Year Queried
          </p>
          <p className="box-value-sm">
            2023
          </p>
          <p className="box-date">
            4/20
          </p>
        </div>
      </div>


    </div>
  )
}

export default TopQueries;