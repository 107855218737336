import tooltipIcon from "../assets/tooltipIcon.svg";

const ToolTip = ({ text }) => {
    return (
        <div className="tooltip">
            <img
                src={tooltipIcon}
                alt="tooltip icon"
                className="tooltip-icon"
            />
            <span className="tooltiptext tooltip-top">{text}</span>
        </div>
    )
}

export default ToolTip;