import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import FunctionsFilter from "./FunctionsFilter";
import DownloadModal from "./DownloadModal";
import UploadFunctionModal from "./UploadFunctionModal";
import EditModal from "./EditModal";
import { UploadSummary, Pagination, Alert } from "../../components";

import uploadIcon from "../../assets/whiteUploadIcon20.svg";
import FiltersIcon from "../../assets/baseFilterIcon20.svg";
import kebabMenu from "../../assets/kebabMenu.svg";
import loadingIcon from "../../assets/loadingIcon.svg";
import { sortIcon, sortIconAsc, sortIconDesc } from "../../assets";
import { ReactComponent as DetailsIcon } from "../../assets/detailsEye.svg";
import { ReactComponent as EditIcon } from "../../assets/editIcon.svg";

import "./Functions.css";
import { format } from "date-fns";

const Functions = () => {
  const navigate = useNavigate();

  const [dataFunctions, setDataFunctions] = useState([]);
  const [isLoadingFunctions, setIsLoadingFunctions] = useState(false);
  const [dataFunction, setDataFunction] = useState({});
  const [dataSampleQuestions, setDataSampleQuestions] = useState([]);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({
    function_name: [],
    time_start: "",
    time_end: "",
  });
  const [activeMenu, setActiveMenu] = useState(null);
  const [isDownloadModalOpen, setDownloadModalOpen] = useState(false);
  const [isUploadModalOpen, setUploadModalOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedSort, setSelectedSort] = useState({
    order_by: "updated_at",
    sort: "desc",
  });
  const itemsPerPage = 10;

  const alertRef = useRef();
  const uploadSummaryRef = useRef();

  const showUploadSummary = (data) => {
    uploadSummaryRef.current.show(data);
  };

  const showAlert = (data) => {
    alertRef.current.show(data);
  };

  const handleFilterApply = (filters) => {
    setSelectedFilters(filters);
    handleCloseModal("Filter");
  };

  const handleOpenModal = (option) => {
    if (option === "Filter") {
      setIsFilterModalOpen(true);
    } else if (option === "Download") {
      setDownloadModalOpen(true);
    } else if (option === "Upload") {
      setUploadModalOpen(true);
    } else if (option === "Edit") {
      setEditModalOpen(true);
    }
  };

  const handleCloseModal = (option) => {
    if (option === "Filter") {
      setIsFilterModalOpen(false);
    } else if (option === "Download") {
      setDownloadModalOpen(false);
    } else if (option === "Upload") {
      setUploadModalOpen(false);
    } else if (option === "Edit") {
      setEditModalOpen(false);
    }
  };

  const handleMenuClick = (index) => {
    setActiveMenu(activeMenu === index ? null : index);
  };

  const handleDetailsClick = async (item) => {
    navigate("/prompt-management/functions/details", {
      state: { id: item.id },
    });
  };

  const handleUploadSuccess = ({ success, failed, detail }) => {
    showUploadSummary({
      type: "success",
      success: success,
      failed: failed,
      detail: detail,
    });
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const fetchDataFunctions = useCallback((params) => {
    setIsLoadingFunctions(true);
    axios({
      method: "post",
      baseURL: "https://genai-cms-api.nawatech.co/api/sma",
      url: `prompt_function/get/`,
      data: params,
    })
      .then((response) => {
        const {
          data: {
            data: { result, total_page },
            code,
          },
        } = response;
        // console.log("response", response);
        if (code !== 200) {
          console.log("Error fetching data: ", response);
          return;
        }
        setDataFunctions(result || []);
        setTotalPages(total_page || 1);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
    setIsLoadingFunctions(false);
  }, []);

  const getDataFunction = useCallback((params) => {
    // API call to get function details
    return axios({
      method: "post",
      baseURL: "https://genai-cms-api.nawatech.co/api/sma",
      url: `prompt_function/get/detail/`,
      params: params,
    });
  }, []);

  const handleEditClick = async (item) => {
    try {
      const response = await getDataFunction({ id: item.id });
      const {
        data: {
          code,
          data: { function: functionData, sample_question: sampleQuestions },
          detail,
        },
      } = response;

      if (code !== 200) {
        showAlert({
          type: "error",
          title: code || "Error",
          message: detail || "Error fetching data",
        });
        return;
      }

      setDataFunction(functionData);
      setDataSampleQuestions(sampleQuestions || []);
      handleOpenModal("Edit");
    } catch (error) {
      const { message, response: { data: { detail } = {} } = {} } = error;

      showAlert({
        type: "error",
        title: message || "Error",
        message: detail || "Error fetching data",
      });
    }
  };

  useEffect(() => {
    fetchDataFunctions({
      ...selectedFilters,
      ...selectedSort,
      page: currentPage,
      size: itemsPerPage,
      is_export: false,
    });
  }, [
    currentPage,
    itemsPerPage,
    selectedFilters,
    selectedSort,
    fetchDataFunctions,
  ]);

  return (
    <>
      <Alert ref={alertRef} />

      <div className="functions-container">
        <div className="functions-header">
          <h1>Functions</h1>
          <button
            className="btn-primary with-icon font-primary"
            onClick={() => handleOpenModal("Upload")}
          >
            <img src={uploadIcon} className="upload-icon" alt="upload icon" />
            Upload Bulk Functions
          </button>
        </div>
        <div className="functions-buttons">
          <button
            className="btn-base font-base"
            onClick={() => handleOpenModal("Download")}
          >
            Download Function
          </button>
          <button
            className="btn-base with-icon font-base"
            onClick={() => handleOpenModal("Filter")}
          >
            <img
              src={FiltersIcon}
              className="filters-icon"
              alt="filters icon"
            />
            <span>Filter</span>
          </button>
        </div>
        <div className="functions-table">
          <table>
            <thead>
              <tr>
                <th>
                  <div className="group-flex-8 items-center">
                    <span>Function Name</span>
                    <img
                      src={
                        selectedSort.order_by === "name"
                          ? selectedSort.sort === "desc"
                            ? sortIconAsc
                            : sortIconDesc
                          : sortIcon
                      }
                      className="sort-icon"
                      alt="Sort icon"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setSelectedSort({
                          order_by: "name",
                          sort:
                            selectedSort.order_by === "name"
                              ? selectedSort.sort === "asc"
                                ? "desc"
                                : "asc"
                              : "asc",
                        });
                      }}
                    />
                  </div>
                </th>
                <th>Description</th>
                <th>
                  <div className="group-flex-8 align-center">
                    <span>Last Update</span>
                    <img
                      src={
                        selectedSort.order_by === "updated_at"
                          ? selectedSort.sort === "desc"
                            ? sortIconAsc
                            : sortIconDesc
                          : sortIcon
                      }
                      className="sort-icon"
                      alt="Sort icon"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setSelectedSort({
                          order_by: "updated_at",
                          sort:
                            selectedSort.order_by === "updated_at"
                              ? selectedSort.sort === "asc"
                                ? "desc"
                                : "asc"
                              : "asc",
                        });
                      }}
                    />
                  </div>
                </th>
                <th> </th>
              </tr>
            </thead>
            <tbody>
              {isLoadingFunctions ?
                <tr>
                  <td colSpan={1} className="text-center">
                    <img
                      src={loadingIcon}
                      alt="Loading..."
                    />
                  </td>
                </tr>
                : dataFunctions.map((item, index) => (
                  <tr key={index}>
                    <td>{item.name}</td>
                    <td>
                      {item.description.length > 150
                        ? item.description.slice(0, 150) + "..."
                        : item.description}
                    </td>
                    <td>{format(new Date(item.updated_at), "yyyy-MM-dd hh:mm:ss.SSX")}</td>
                    <td style={{ position: "relative" }}>
                      <img
                        src={kebabMenu}
                        alt="kebab menu"
                        onClick={() => handleMenuClick(index)}
                        style={{ cursor: "pointer" }}
                      />
                      {activeMenu === index && (
                        <div className="functions-menu">
                          <div
                            className="functions-menu-option"
                            onClick={() => handleDetailsClick(item)}
                          >
                            <DetailsIcon className="functions-menu-icon" />
                            View Details
                          </div>
                          <div
                            className="functions-menu-option"
                            onClick={() => handleEditClick(item)}
                          >
                            <EditIcon className="functions-menu-icon" />
                            Edit
                          </div>
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          <Pagination
            totalPages={totalPages}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
          />
        </div>
        <FunctionsFilter
          isOpen={isFilterModalOpen}
          onClose={() => handleCloseModal("Filter")}
          onApply={handleFilterApply}
        />
        <DownloadModal
          isOpen={isDownloadModalOpen}
          onClose={() => handleCloseModal("Download")}
          filters={selectedFilters}
        />
        <UploadSummary ref={uploadSummaryRef} />
        <UploadFunctionModal
          isOpen={isUploadModalOpen}
          onClose={() => handleCloseModal("Upload")}
          onUploaded={handleUploadSuccess}
          onError={(error) => {
            const { message, response: { data: { detail } = {} } = {} } = error;

            showAlert({
              type: "error",
              title: message,
              message: detail,
            });
          }}
        />
        <EditModal
          isOpen={isEditModalOpen}
          onClose={() => handleCloseModal("Edit")}
          onSuccess={() => {
            handleCloseModal("Edit");
            fetchDataFunctions({
              ...selectedFilters,
              ...selectedSort,
              page: currentPage,
              size: itemsPerPage,
              is_export: false,
            });
          }}
          formData={{
            function: dataFunction,
            sampleQuestions: dataSampleQuestions,
          }}
        />
      </div>
    </>
  );
};

export default Functions;
