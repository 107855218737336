import { getMonth, getYear } from "date-fns";
import chevronLeft from "../assets/chevronLeft.svg";
import chevronRight from "../assets/chevronRight.svg";


const CustomeHeaderDatePicker = (date, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled) => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  return (
    <div
      className="date-picker-header"
    >
      <button
        onClick={decreaseMonth}
        disabled={prevMonthButtonDisabled}
        className="month-button">
        <img
          src={chevronLeft}
          alt="calendar icon" />
      </button>
      <p>
        {months[getMonth(date)]} {getYear(date)}
      </p>
      <button
        onClick={increaseMonth}
        disabled={nextMonthButtonDisabled}
        className="month-button">
        <img
          src={chevronRight}
          alt="calendar icon" />
      </button>
    </div>
  )
}

export default CustomeHeaderDatePicker;