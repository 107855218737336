import React, { useState } from "react";
import cancelIcon from "../../assets/cancelIcon.svg";
import starIcon from "../../assets/starIcon.svg"
import fullStarIcon from "../../assets/fullStar.svg"
import halfStarIcon from "../../assets/halfStar.svg"
import emptyStarIcon from "../../assets/emptyStar.svg"
import sortIcon from "../../assets/sortIcon.svg"
import "./ResponseQuality.css";

const UserRating = ({ isOpen, onClose }) => {
  const ratings = [
    { stars: 5, count: 385 },
    { stars: 4, count: 80 },
    { stars: 3, count: 20 },
    { stars: 2, count: 10 },
    { stars: 1, count: 30 },
  ];

  const feedbacks = [
    {
      email: "username@mail.com",
      timestamp: "2024-07-25 10:55",
      reason: "Akurat",
      comment:
        "Lorem ipsum dolor sit amet consectetur. Nibh ac nisl quis magna. Habitant semper malesuada leo libero. Nulla interdum placerat ultrices aenean ut.",
    },
    {
      email: "username@mail.com",
      timestamp: "2024-07-25 10:55",
      reason: "Konsisten",
      comment:
        "Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur. Vestibulum ornare ac diam sem elementum neque.",
    },
    {
      email: "username@mail.com",
      timestamp: "2024-07-25 10:55",
      reason: "Konsisten",
      comment:
        "Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur. Vestibulum ornare ac diam sem elementum neque.",
    },
    {
      email: "username@mail.com",
      timestamp: "2024-07-25 10:55",
      reason: "Konsisten",
      comment:
        "Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur. Vestibulum ornare ac diam sem elementum neque.",
    },
    {
      email: "username@mail.com",
      timestamp: "2024-07-25 10:55",
      reason: "Konsisten",
      comment:
        "Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur. Vestibulum ornare ac diam sem elementum neque.",
    },
    {
      email: "username@mail.com",
      timestamp: "2024-07-25 10:55",
      reason: "Konsisten",
      comment:
        "Lorem ipsum dolor sit amet consectetur. Lorem ipsum dolor sit amet consectetur. Vestibulum ornare ac diam sem elementum neque.",
    },
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 3;
  
  const indexOfLastFeedback = currentPage * rowsPerPage;
  const indexOfFirstFeedback = indexOfLastFeedback - rowsPerPage;
  const currentFeedbacks = feedbacks.slice(indexOfFirstFeedback, indexOfLastFeedback);

  const paginate = (direction) => {
    if (direction === 'next' && currentPage < Math.ceil(feedbacks.length / rowsPerPage)) {
      setCurrentPage(currentPage + 1);
    } else if (direction === 'prev' && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  if (!isOpen) return null;

  const totalStars = 5;
  const rating = 4.4;
  const filledStars = Math.floor(rating);
  const halfStar = rating % 1 > 0 ? 1 : 0;
  const emptyStars = totalStars - filledStars - halfStar;

  return (
    <div className={`user-rating ${isOpen ? "open" : ""}`}>
      <div className="user-rating-content">
        <div className="user-rating-header">
          <h1 className="user-rating-title">User Rating</h1>
          <button className="rating-close-button" onClick={onClose}>
            <img src={cancelIcon} alt="cancel icon" />
          </button>
        </div>
        <div className="rating-separator" />
        <div className="rating-summary">
          <div className="rating-number">
            <h2>4.4</h2>
            <div className="stars">
              {Array.from({ length: filledStars }, (_, index) => (
                <span key={`filled-${index}`}><img src={fullStarIcon} alt="full star" /></span>
              ))}
              {halfStar === 1 && <span><img src={halfStarIcon} alt="half star" /></span>}
              {Array.from({ length: emptyStars }, (_, index) => (
                <span key={`empty-${index}`} className="empty-star"><img src={emptyStarIcon} alt="empty star" /></span>
              ))}
            </div>
            <p>500 feedback</p>
          </div>
          <div className="rating-breakdown">
            {ratings.map((rating) => (
              <div key={rating.stars} className="rating-bar">
                <div className="rating-star">
                  <img src={starIcon} alt="star" />
                  {rating.stars}
                </div>
                <div className="bar">
                  <div
                    className="bar-fill"
                    style={{
                      width: `${(rating.count / 385) * 100}%`
                    }}
                  ></div>
                </div>
                <span className="rating-star">{rating.count}</span>
              </div>
            ))}
          </div>
        </div>

        <div className="feedback-section">
          <table className="feedback-table">
            <thead>
              <tr>
                <th>Email</th>
                <th>
                  Timestamp
                  <img src={sortIcon} alt="sort icon" className="sort-icon" />
                </th>
                <th>Reason</th>
                <th>Comment</th>
              </tr>
            </thead>
            <tbody>
              {currentFeedbacks.map((feedback, index) => (
                <tr key={index}>
                  <td>{feedback.email}</td>
                  <td>{feedback.timestamp}</td>
                  <td>{feedback.reason}</td>
                  <td>{feedback.comment}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="user-rating-pagination">
            <button onClick={() => paginate('prev')} disabled={currentPage === 1}>Previous</button>
            {/* <span>Page {currentPage} of {Math.ceil(feedbacks.length / rowsPerPage)}</span> */}
            <button onClick={() => paginate('next')} disabled={currentPage === Math.ceil(feedbacks.length / rowsPerPage)}>Next</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserRating;
