import React, { useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import calendarIcon from "../../assets/baseCalendarIcon20.svg";
import filterIcon from "../../assets/baseFilterIcon20.svg";
import tooltipIcon from "../../assets/tooltipIcon.svg";
import TCCLineChart from "./TCCLineChart";
import TCCDonutChart from "./TCCDonutChart";
import TCCFilterModal from "./TCCFilterModal";
import TCCDateModal from "./TCCDateModal"
import sampleData from "./sampleData.json";
import "./TokenCostConsumption.css";
import ToolTip from "../../components/ToolTip";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const TokenCostConsumption = () => {
  const [data, setData] = useState(sampleData);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [isDateModalOpen, setIsDateModalOpen] = useState(false);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);

  const handleOpenModal = (modal) => {
    switch (modal) {
      case "date":
        setIsDateModalOpen(true);
        break;
      case "filter":
        setIsFilterModalOpen(true);
        break;
      default:
        break;
    }
  };

  const handleOpenCloseDateModal = () => {
    if (isDateModalOpen) {
      setIsDateModalOpen(false);
    } else {
      setIsDateModalOpen(true);
    }
  }

  const handleCloseModal = (modal) => {
    switch (modal) {
      case "date":
        setIsDateModalOpen(false);
        break;
      case "filter":
        setIsFilterModalOpen(false);
        break;
      default:
        break;
    }
  };

  const handleFilterApply = (filters) => {
    setSelectedFilters(filters);
    handleCloseModal("filter");
  };

  return (
    <div className="response-quality-container">
      <div className="rq-header-section">
        <h1 className="rq-header-title">Token/Cost Consumption</h1>
        <div className="group-flex-8">
          <div className="dropdown-calendar">
            <button className="btn-date" onClick={() => handleOpenCloseDateModal()}>
              <img
                src={calendarIcon}
                alt="calendar icon"
              />
              {selectedDate ?? "Choose Date"}
            </button>
            <TCCDateModal isOpen={isDateModalOpen} onClose={() => handleCloseModal("date")} onSelectedDate={setSelectedDate} />

          </div>
          <button
            className="btn-base with-icon font-base"
            onClick={() => handleOpenModal("filter")}
          >
            <img
              src={filterIcon}
              alt="filter icon"
              className="filter-button-icon"
            />
            Filter
          </button>
        </div>
      </div>

      <TCCFilterModal
        isOpen={isFilterModalOpen}
        onClose={() => handleCloseModal("filter")}
        onApply={handleFilterApply}
      />

      <div className="rq-overview-section">
        <h2 className="rq-overview-title">Overview</h2>
        <div className="rq-overview-boxes">
          <div className="rq-overview-box">
            <p className="box-title">
              Total Token Consumption
              <ToolTip text={'The total number of token consumption'} />
            </p>
            <p className="box-value">94%</p>
            <p className="box-date">+10% from last month</p>
          </div>
          <div className="rq-overview-box">
            <p className="box-title">
              Total Token Cost Consumption
              <ToolTip text={'The total cost of token consumption'} />
            </p>
            <p className="box-value">94%</p>
            <p className="box-date">+10% from last month</p>
          </div>
          <div className="rq-overview-box">
            <p className="box-title">
              Avg. Token Consumption per Chat
              <ToolTip text={'The average number of tokens used per chat'} />
            </p>
            <p className="box-value">94%</p>
            <p className="box-date">+10% from last month</p>
          </div>
          <div className="rq-overview-box">
            <p className="box-title">
              Avg. Token Cost Consumption per Chat
              <ToolTip text={'The average cost of token consumption per chat'} />
            </p>
            <p className="box-value">94%</p>
            <p className="box-date">+10% from last month</p>
          </div>
        </div>
      </div>

      <div className="rq-charts-section">
        <TCCLineChart />
        <TCCDonutChart />
      </div>

      <div className="rq-table">
        <table>
          <thead>
            <tr>
              <th>Function</th>
              <th>Avg. Token Consumption</th>
              <th>Avg. Token Cost Consumption</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <td>{item.function}</td>
                <td>{item.token}</td>
                <td>{item.token_cost}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TokenCostConsumption;
