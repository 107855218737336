import React, { useState, useEffect } from 'react';
import cancelIcon from "../assets/cancelIcon.svg";

const FilterModal = ({ isOpen, onClose, onApply }) => {
  const [filters, setFilters] = useState({
    detectedCapability: null,
    selectedCapability: null,
  });

  const [capabilities, setCapabilities] = useState([]);

  useEffect(() => {
    if (isOpen) {
      fetch('https://genai-cms-api.nawatech.co/api/sma/fallback/get-master-capability')
        .then((response) => response.json())
        .then((data) => {
          if (data.code === 200) {
            setCapabilities(data.data);
          }
        })
        .catch((error) => {
          console.error('Error fetching capabilities:', error);
        });
    }
  }, [isOpen]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value === '' ? null : value,
    }));
  };

  const handleClear = () => {
    const clearedFilters = {
      detectedCapability: null,
      selectedCapability: null
    };
    setFilters(clearedFilters);
    onApply(clearedFilters);
    onClose();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onApply(filters);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="filter-modal-overlay">
      <div className="filter-modal">
        <div className="filter-header">
          <h2 className="filter-title">Filter</h2>
          <button className="filter-close" onClick={onClose}>
            <img
              src={cancelIcon}
              alt="Close"
            />
          </button>
        </div>
        <hr className="filter-separator" />
        <form className="filter-form" onSubmit={handleSubmit}>
          <div className="filter-form-group">
            <label htmlFor="detected-capability">Detected Capability</label>
            <select
              id="detected-capability"
              name="detectedCapability"
              value={filters.detectedCapability || ''}
              onChange={handleInputChange}
            >
              <option value="">-- Select Detected Capability --</option>
              {capabilities.map((capability, index) => (
                <option key={index} value={capability}>
                  {capability}
                </option>
              ))}
            </select>
          </div>
          <div className="filter-form-group">
            <label htmlFor="selected-capability">Selected Capability</label>
            <select
              id="selected-capability"
              name="selectedCapability"
              value={filters.selectedCapability || ''}
              onChange={handleInputChange}
            >
              <option value="">-- Select Selected Capability --</option>
              {capabilities.map((capability, index) => (
                <option key={index} value={capability}>
                  {capability}
                </option>
              ))}
            </select>
          </div>
          <div className="filter-form-actions">
            <button type="button" className="clear-button" onClick={handleClear}>
              Clear
            </button>
            <button type="submit" className="apply-button">
              Apply
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FilterModal;
