import React, { useCallback, useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import tooltipIcon from "../../assets/tooltipIcon.svg";
import openModalIcon from "../../assets/openModal.svg";
import UserSatisfaction from "./UserSatisfaction";
import "./ResponseQuality.css";
import ToolTip from "../../components/ToolTip";
import axios from "axios";
import debounce from "../../utils/Debounce";

const RQDonutChart = ({ onOpenUserSatisfaction, selectedFilters, selectedMonth, selectedYear }) => {

  const [userSatisfaction, setUserSatisfaction] = useState({});

  const getUserSatisfactionData = useCallback(debounce(async (filter, month, year) => {
    try {
      const url = `https://genai-cms-api.nawatech.co/api/sma/response-quality/total-user-satisfaction`;
      const response = await axios.post(url, {
        year: filter.year,
        psm: filter.psm,
        estate: filter.estate,
        month: filter.month,
        metrics: filter.metrics,
        region: filter.region,
        date_months: month.sort((a, b) => a - b),
        date_years: year.sort((a, b) => a - b)
      });
      if (response.status == 200) {
        const result = response.data;
        setUserSatisfaction(result.data.user_satisfaction);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, 2000), [])


  useEffect(() => {
    getUserSatisfactionData(selectedFilters, selectedMonth, selectedYear);
  }, [selectedFilters, selectedMonth, selectedYear])

  const data = {
    labels: ["Thumbs Up", "Thumbs Down"],
    datasets: [
      {
        data: [
          userSatisfaction.thumbs_up ? userSatisfaction.thumbs_up.total : 15,
          userSatisfaction.thumbs_down ? userSatisfaction.thumbs_down.total : 15],
        backgroundColor: ["#33D616", "#F77B65"],
        hoverBackgroundColor: ["#33D6164D", "#F77B654D"],
      },
    ],
  };

  const chartOptions = {
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const legendData = [
    {
      label: "Thumbs Up",
      value: userSatisfaction.thumbs_up ? userSatisfaction.thumbs_up.total : 15,
      percentage: userSatisfaction.thumbs_down ? userSatisfaction.thumbs_up.percentage : 15,
      color: "#33D616",
    },
    {
      label: "Thumbs Down",
      value: userSatisfaction.thumbs_up ? userSatisfaction.thumbs_down.total : 10,
      percentage: userSatisfaction.thumbs_down ? userSatisfaction.thumbs_down.percentage : 10,
      color: "#F77B65",
    },
  ];

  return (
    <div className="rq-donut-container">
      <h1 className="rq-donut-title">
        Total User Satisfaction
        <ToolTip text={"A comparison of total user satisfaction based on the total number of thumbs-up and thumbs-down reactions"} />
        <img
          src={openModalIcon}
          alt="redirect icon"
          className="rq-open-modal"
          onClick={onOpenUserSatisfaction}
        />
      </h1>
      <div className="rq-chart-and-legend">
        <Doughnut className="rq-donut" data={data} options={chartOptions} />
        <div className="rq-legend-container">
          {legendData.map((item, index) => (
            <div key={index} className="rq-legend-item">
              <div className="rq-legend-info">
                <div
                  className="rq-legend-color-box"
                  style={{ backgroundColor: item.color }}
                ></div>
                <span style={{ marginRight: "10px" }}>{item.label}</span>
              </div>
              <div className="rq-legend-value">
                <span className="rq-value">{item.value}</span>
                <div className="rq-percentage-bar">
                  <div
                    className="rq-percentage-fill"
                    style={{
                      width: item.percentage + "%",
                      backgroundColor: item.color,
                    }}
                  ></div>
                </div>
                <span className="rq-percentage-text">{item.percentage}%</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RQDonutChart;
