import React from "react";
import { Doughnut } from "react-chartjs-2";
import tooltipIcon from "../../assets/tooltipIcon.svg";
import "./TokenCostConsumption.css";
import ToolTip from "../../components/ToolTip";

const TCCDonutChart = () => {
  const data = {
    labels: ["Total Input Token", "Total Output Token"],
    datasets: [
      {
        data: [440, 60],
        backgroundColor: ["#16BFD6", "#F765A3"],
        hoverBackgroundColor: ["#16BFD64D", "#F765A34D"],
      },
    ],
  };

  const chartOptions = {
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const totalVotes = 440 + 60;
  const totalInputToken = 440;
  const totalOutputToken = 60;
  const inputPercentage = ((totalInputToken / totalVotes) * 100).toFixed(0);
  const outputPercentage = ((totalOutputToken / totalVotes) * 100).toFixed(
    0
  );

  const legendData = [
    {
      label: "Total Input Token",
      value: totalInputToken,
      percentage: inputPercentage,
      color: "#16BFD6",
    },
    {
      label: "Total Output Token",
      value: totalOutputToken,
      percentage: outputPercentage,
      color: "#F765A3",
    },
  ];

  return (
    <div className="rq-donut-container">
      <h1 className="rq-donut-title">
        Input & Output Token
        <ToolTip text={'A comparison of the total consumption of Input Token and Output Token'} />
      </h1>
      <div className="rq-chart-and-legend">
        <Doughnut className="rq-donut" data={data} options={chartOptions} />
        <div className="rq-legend-container">
          {legendData.map((item, index) => (
            <div key={index} className="rq-legend-item">
              <div className="rq-legend-info">
                <div
                  className="rq-legend-color-box"
                  style={{ backgroundColor: item.color }}
                ></div>
                <span style={{ marginRight: "10px" }}>{item.label}</span>
              </div>
              <div className="rq-legend-value">
                <span className="rq-value">{item.value}</span>
                <div className="rq-percentage-bar">
                  <div
                    className="rq-percentage-fill"
                    style={{
                      width: item.percentage + "%",
                      backgroundColor: item.color,
                    }}
                  ></div>
                </div>
                <span className="rq-percentage-text">{item.percentage}%</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TCCDonutChart;
