import React from "react";
import successAlert from "./assets/successAlert.svg";
import cancelIcon from "./assets/cancelNotification.svg";

const SuccessAlert = ({ onClose, message }) => {
  return (
    <div className="notification">
      <img src={successAlert} alt="success alert" />
      <p>{message || "Changes was successfully saved"}</p>
      <img src={cancelIcon} alt="close icon" onClick={onClose} />
    </div>
  );
};

export default SuccessAlert;
