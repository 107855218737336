import React, { useState, forwardRef, useImperativeHandle } from "react";
import cancelIcon from "./../../assets/cancelNotification.svg";

import "./UploadSummary.css";

const UploadSummary = forwardRef((props, ref) => {
  const [alert, setAlert] = useState(null);

  useImperativeHandle(ref, () => ({
    show: ({ type, success, failed, detail }) => {
      setAlert({ type, success, failed, detail });
    },
    hide: () => {
      setAlert(null);
    },
  }));

  const onClose = () => {
    setAlert(null);
  };

  return (
    alert && (
      <div className="modal-overlay upload-summary-modal">
        <div className="modal-content">
          <h2 className="modal-title">Upload Bulk Summary</h2>
          <button className="close-button" onClick={onClose}>
            <img src={cancelIcon} alt="cancel icon" onClick={onClose} />
          </button>
          <hr className="modal-separator" />

          <div className="upload-success-badge">
            Successfully Uploaded: {alert.success}
          </div>
          <hr className="upload-badge-separator" />
          <div className="upload-error-badge">
            Failed to Upload: {alert.failed}
          </div>

          {alert.failed > 0 && (
            <div className={`upload-message error`}>
              <div className="message-title">{alert.detail?.title}</div>
              <hr className="upload-message-separator" />
              <div className="message-description">{alert.detail?.message}</div>
            </div>
          )}
        </div>
      </div>
    )
  );
});

export default UploadSummary;
