import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import calendarIcon from "../../assets/baseCalendarIcon20.svg";
import filterIcon from "../../assets/baseFilterIcon20.svg";
import tooltipIcon from "../../assets/tooltipIcon.svg";
import openModalIcon from "../../assets/openModal.svg";
import redirectIcon from "../../assets/redirectIcon.svg";
import RQLineChart from "./RQLineChart";
import RQDonutChart from "./RQDonutChart";
import RQFilterModal from "./RQFilterModal";
import RQDateModal from "./RQDateModal"
import UserRating from "./UserRating";
import UserSatisfaction from "./UserSatisfaction";
import sampleData from "./sampleData";
import "./ResponseQuality.css";
import ToolTip from "../../components/ToolTip";
import FunctionTable from "./FunctionTable";
import axios from "axios";
import debounce from "../../utils/Debounce";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const ResponseQuality = () => {
  const [selectedFilters, setSelectedFilters] = useState({
    year: "",
    psm: "",
    estate: "",
    month: "",
    metrics: "",
    region: "",
  });
  const [overviewData, setOverviewData] = useState({});
  const [isDateModalOpen, setIsDateModalOpen] = useState(false);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [isUserRatingOpen, setUserRatingOpen] = useState(false);
  const [isUserSatisfactionOpen, setUserSatisfactionOpen] = useState(false);
  const [isLoadingOverview, setIsLoadingOverview] = useState(true);

  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();

  const [selectedMonth, setSelectedMonth] = useState([currentMonth]);
  const [selectedYear, setSelectedYear] = useState([currentYear]);

  const navigate = useNavigate();

  const fetchOverviewData = useCallback(debounce(async (filter, month, year) => {
    setIsLoadingOverview(true);
    try {
      const url = `https://genai-cms-api.nawatech.co/api/sma/response-quality/overview`;
      const response = await axios.post(url, {
        year: filter.year,
        psm: filter.psm,
        estate: filter.estate,
        month: filter.month,
        metrics: filter.metrics,
        region: filter.region,
        date_months: month.sort((a, b) => a - b),
        date_years: year.sort((a, b) => a - b)
      });
      if (response.status == 200) {
        const result = response.data;
        setOverviewData(result.data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setIsLoadingOverview(false);
  }, 2000), []);

  useEffect(() => {
    fetchOverviewData(selectedFilters, selectedMonth, selectedYear);
  }, [selectedFilters, selectedMonth, selectedYear]);

  const handleRedirect = () => {
    navigate("/fallback-management");
  };

  const handleOpenCloseDateModal = () => {
    if (isDateModalOpen) {
      setIsDateModalOpen(false);
    } else {
      setIsDateModalOpen(true);
    }
  }

  const handleOpenModal = (modal) => {
    switch (modal) {
      case "date":
        setIsDateModalOpen(true);
        break;
      case "filter":
        setIsFilterModalOpen(true);
        break;
      case "userRating":
        setUserRatingOpen(true);
        break;
      case "userSatisfaction":
        setUserSatisfactionOpen(true);
        break;
      default:
        break;
    }
  };

  const handleCloseModal = (modal) => {
    switch (modal) {
      case "date":
        setIsDateModalOpen(false);
        break;
      case "filter":
        setIsFilterModalOpen(false);
        break;
      case "userRating":
        setUserRatingOpen(false);
        break;
      case "userSatisfaction":
        setUserSatisfactionOpen(false);
        break;
      default:
        break;
    }
  };

  const handleFilterApply = (filters) => {
    setSelectedFilters(filters);
    handleCloseModal("filter");
  };

  return (
    <div className="response-quality-container">
      <div className="rq-header-section">
        <h1 className="rq-header-title">Response Quality</h1>
        <div className="group-flex-8">
          <div className="dropdown-calendar">
            <button className="btn-date large cursor-pointer" onClick={() => handleOpenCloseDateModal()}>
              <img
                src={calendarIcon}
                alt="calendar icon"
                className="date-button-icon"
              />
              {"Choose Date"}
            </button>
            <RQDateModal
              isOpen={isDateModalOpen}
              onClose={() => handleCloseModal("date")}
              selectedMonth={selectedMonth}
              selectedYear={selectedYear}
              setSelectedMonth={setSelectedMonth}
              setSelectedYear={setSelectedYear}
              currentMonth={currentMonth}
              currentYear={currentYear}
            />
          </div>
          <button
            className="btn-base with-icon font-base"
            onClick={() => handleOpenModal("filter")}
          >
            <img
              src={filterIcon}
              alt="filter icon"
              className="filter-button-icon"
            />
            Filter
          </button>
        </div>
      </div>

      <RQFilterModal
        isOpen={isFilterModalOpen}
        onClose={() => handleCloseModal("filter")}
        onApply={handleFilterApply}
      />
      <UserRating isOpen={isUserRatingOpen} onClose={() => handleCloseModal("userRating")} />
      <UserSatisfaction isOpen={isUserSatisfactionOpen} onClose={() => handleCloseModal("userSatisfaction")} />

      <div className="rq-overview-section">
        <h2 className="rq-overview-title">Overview</h2>
        <div className="rq-overview-boxes">
          <div className="rq-overview-box">
            <p className="box-title">
              Success Rate
              <ToolTip text={"The percentage of the chatbot's success rate based on the total number of chatbot responses (excluding responses that are directed to Fallback Management)"} />
            </p>
            <p className="box-value">{overviewData.success_rate ? overviewData.success_rate.growth : 0}%</p>
            <p className="box-date">
              <span
                style={{ color: overviewData.success_rate && overviewData.success_rate.rate >= 0 ? 'green' : 'red' }}
              >
                +{overviewData.success_rate ? overviewData.success_rate.rate : 0}% {""}
              </span>
              from last month
            </p>
          </div>
          <div className="rq-overview-box">
            <p className="box-title">
              Error/Fallback Rate
              <ToolTip text={"The percentage of the chatbot's error rate based on the total number of chatbot responses in Fallback Management"} />
              <img
                src={redirectIcon}
                alt="redirect icon"
                className="redirect-icon"
                onClick={handleRedirect}
              />
            </p>
            <p className="box-value">{overviewData.error_rate ? overviewData.error_rate.growth : 0}%</p>
            <p className="box-date">
              <span
                style={{ color: overviewData.error_rate && overviewData.error_rate.rate >= 0 ? 'green' : 'red' }}
              >
                +{overviewData.error_rate ? overviewData.error_rate.rate : 0}% {""}
              </span>
              from last month
            </p>          </div>
          <div className="rq-overview-box">
            <p className="box-title">
              Satisfaction Rate
              <ToolTip text={"The percentage of user satisfaction based on the total number of thumbs-up reactions given by users"} />
            </p>
            <p className="box-value">{overviewData.satisfaction_rate ? overviewData.satisfaction_rate.growth : 0}%</p>
            <p className="box-date">
              <span
                style={{ color: overviewData.satisfaction_rate && overviewData.satisfaction_rate.rate >= 0 ? 'green' : 'red' }}
              >
                +{overviewData.satisfaction_rate ? overviewData.satisfaction_rate.rate : 0}% {""}
              </span>
              from last month
            </p>          </div>
          <div className="rq-overview-box">
            <p className="box-title">
              Avg. User Rating
              <ToolTip text={"The average percentage of user satisfaction based on the ratings given by users"} />
              <img
                src={openModalIcon}
                alt="open modal icon"
                className="redirect-icon"
                onClick={() => handleOpenModal("userRating")}
              />
            </p>
            <p className="box-value">{overviewData.success_rate ? overviewData.success_rate.growth : 0}</p>
            <p className="box-date">
              <span
                style={{ color: overviewData.success_rate && overviewData.success_rate.rate >= 0 ? 'green' : 'red' }}
              >
                +{overviewData.success_rate ? overviewData.success_rate.rate : 0}% {""}
              </span>
              from last month
            </p>          </div>
          <div className="rq-overview-box">
            <p className="box-title">
              Avg. Response Time
              <ToolTip text={"The average percentage of time taken by chatbot to respond to users"} />
            </p>
            <p className="box-value">{overviewData.response_time ? overviewData.response_time.growth : 0}%</p>
            <p className="box-date">
              <span
                style={{ color: overviewData.response_time && overviewData.response_time.rate >= 0 ? 'green' : 'red' }}
              >
                +{overviewData.response_time ? overviewData.response_time.rate : 0}% {""}
              </span>
              from last month
            </p>
          </div>
        </div>
      </div>

      <div className="rq-charts-section">
        <RQLineChart selectedFilters={selectedFilters} selectedMonth={selectedMonth} selectedYear={selectedYear} />
        <RQDonutChart onOpenUserSatisfaction={() => handleOpenModal("userSatisfaction")} selectedFilters={selectedFilters} selectedMonth={selectedMonth} selectedYear={selectedYear} />
      </div>

      <FunctionTable selectedFilters={selectedFilters} selectedMonth={selectedMonth} selectedYear={selectedYear} />
    </div>
  );
};

export default ResponseQuality;
