import React, { useState, useEffect } from 'react';
import './FallbackManagement.css';
import cancelIcon from "../assets/cancelIcon.svg";

const monthOptions = [
  { name: 'Jan', value: 1 },
  { name: 'Feb', value: 2 },
  { name: 'Mar', value: 3 },
  { name: 'Apr', value: 4 },
  { name: 'May', value: 5 },
  { name: 'Jun', value: 6 },
  { name: 'Jul', value: 7 },
  { name: 'Aug', value: 8 },
  { name: 'Sep', value: 9 },
  { name: 'Oct', value: 10 },
  { name: 'Nov', value: 11 },
  { name: 'Dec', value: 12 },
];

const AnalyticsFilterModal = ({ isOpen, onClose, onApply }) => {
  const [filterOptions, setFilterOptions] = useState({
    detectedCapability: [],
    year: [],
    metrics: [],
    psm: [],
    region: [],
    estate: [],
  });

  const [filters, setFilters] = useState({
    detectedCapability: null,
    month: null,
    year: null,
    metrics: null,
    psm: null,
    region: null,
    estate: null,
  });

  const fetchFilterOptions = (filter) => {
    return fetch(`https://genai-cms-api.nawatech.co/api/sma/fallback/get-master-analytic-filter?filter=${filter}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.code === 200) {
          return data.data.map((item) => ({ name: item }));
        } else {
          console.error(`Error fetching ${filter} options:`, data.message);
          return [];
        }
      })
      .catch((error) => {
        console.error(`Error fetching ${filter} options:`, error);
        return [];
      });
  };

  const fetchCapabilities = () => {
    return fetch('https://genai-cms-api.nawatech.co/api/sma/fallback/get-master-capability/w/not-found')
      .then((response) => response.json())
      .then((data) => {
        if (data.code === 200) {
          return data.data.map((item) => ({ name: item }));
        } else {
          console.error('Error fetching capabilities:', data.message);
          return [];
        }
      })
      .catch((error) => {
        console.error('Error fetching capabilities:', error);
        return [];
      });
  };

  useEffect(() => {
    if (isOpen) {
      fetchCapabilities().then((data) => {
        setFilterOptions((prevOptions) => ({
          ...prevOptions,
          detectedCapability: data,
        }));
      });

      const filtersToFetch = ['year', 'metrics', 'psm', 'region', 'estate'];
      filtersToFetch.forEach((filter) => {
        fetchFilterOptions(filter).then((data) => {
          setFilterOptions((prevOptions) => ({
            ...prevOptions,
            [filter]: data,
          }));
        });
      });
    }
  }, [isOpen]);

  if (!isOpen) return null;

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value === "ALL" ? null : value,
    }));
  };

  const handleClear = () => {
    const clearedFilters = {
      detectedCapability: null,
      month: null,
      year: null,
      metrics: null,
      psm: null,
      region: null,
      estate: null,
    };
    setFilters(clearedFilters);
    onApply(clearedFilters);
    onClose();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onApply(filters);
    onClose();
  };

  return (
    <div className="analytics-filter-modal-overlay">
      <div className="analytics-filter-modal">
        <div className="filter-header">
          <h2 className="filter-title">Filter</h2>
          <button className="filter-close" onClick={onClose}>
            <img
              src={cancelIcon}
              alt="Close"
            />
          </button>
        </div>
        <hr className="analytics-filter-separator" />
        <form className="analytics-filter-form" onSubmit={handleSubmit}>
          <div className="analytics-filter-form-group">
            <label htmlFor="detected-capability">Detected Capability</label>
            <select
              id="detected-capability"
              name="detectedCapability"
              value={filters.detectedCapability || ""}
              onChange={handleInputChange}
            >
              <option value="ALL">ALL</option>
              {filterOptions.detectedCapability.map((option, index) => (
                <option key={index} value={option.name}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>
          <div className="analytics-filter-form-group">
            <label htmlFor="month">Month</label>
            <select
              id="month"
              name="month"
              value={filters.month || ""}
              onChange={handleInputChange}
            >
              <option value="ALL">ALL</option>
              {monthOptions.map((option, index) => (
                <option key={index} value={option.value}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>
          <div className="analytics-filter-form-group">
            <label htmlFor="year">Year</label>
            <select
              id="year"
              name="year"
              value={filters.year || ""}
              onChange={handleInputChange}
            >
              <option value="ALL">ALL</option>
              {filterOptions.year.map((option, index) => (
                <option key={index} value={option.name}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>
          <div className="analytics-filter-form-group">
            <label htmlFor="metrics">Metrics</label>
            <select
              id="metrics"
              name="metrics"
              value={filters.metrics || ""}
              onChange={handleInputChange}
            >
              <option value="ALL">ALL</option>
              {filterOptions.metrics.map((option, index) => (
                <option key={index} value={option.name}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>
          <div className="analytics-filter-form-group">
            <label htmlFor="psm">PSM</label>
            <select
              id="psm"
              name="psm"
              value={filters.psm || ""}
              onChange={handleInputChange}
            >
              <option value="ALL">ALL</option>
              {filterOptions.psm.map((option, index) => (
                <option key={index} value={option.name}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>
          <div className="analytics-filter-form-group">
            <label htmlFor="region">Region</label>
            <select
              id="region"
              name="region"
              value={filters.region || ""}
              onChange={handleInputChange}
            >
              <option value="ALL">ALL</option>
              {filterOptions.region.map((option, index) => (
                <option key={index} value={option.name}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>
          <div className="analytics-filter-form-group full-width">
            <label htmlFor="estate">Estate</label>
            <select
              id="estate"
              name="estate"
              value={filters.estate || ""}
              onChange={handleInputChange}
            >
              <option value="ALL">ALL</option>
              {filterOptions.estate.map((option, index) => (
                <option key={index} value={option.name}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>
          <div className="analytics-filter-form-actions">
            <button type="button" className="clear-button" onClick={handleClear}>
              Clear
            </button>
            <button type="submit" className="apply-button">
              Apply
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AnalyticsFilterModal;
