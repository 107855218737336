import React, { useEffect, useRef, useState } from 'react';
import CustomDatePicker from '../../components/CustomDatePicker';
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';

const TCCDateModal = ({ isOpen, onClose, onSelectedDate }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const dateRef = useRef(<DatePicker />);

  const handleDateChange = (dates) => {
    console.log(dates);
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const handleDatePickerClear = () => {
    setStartDate(null);
    setEndDate(null);
  };

  const handleDatePickerClose = () => {
    dateRef.current.setOpen(false);
  }

  const handleDatePickerOpen = () => {
    dateRef.current.setOpen(true);
  }

  const setToday = () => {
    const date = new Date();
    setStartDate(date);
    setEndDate(date);
  }

  const setWeek = () => {
    const date = new Date();
    const day = date.getDay();
    const prevMonday = new Date();
    if (date.getDay() === 0) {
      prevMonday.setDate(date.getDate() - 7);
    }
    else {
      prevMonday.setDate(date.getDate() - (day - 1));
    }
    const end = new Date();
    setStartDate(prevMonday);
    setEndDate(end);
  }

  const setMonth = () => {
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    const end = new Date();
    setStartDate(firstDay);
    setEndDate(end);
  }

  const setYear = () => {
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), 1, 1);
    const end = new Date();
    setStartDate(firstDay);
    setEndDate(end);
  }

  const handleDateApply = () => {
    const start = startDate ? format(startDate, 'yyyy-MM-dd') : null;
    const end = endDate ? format(endDate, 'yyyy-MM-dd') : null;
    if (!start && !end) {
      onSelectedDate(null)
    } else {
      onSelectedDate(`${start ?? ''} To ${end ?? ''}`);
    };
    //Hit API here;
    onClose();
  };

  useEffect(() => {
    if (startDate && endDate) {
      handleDateApply();
    }
  }, [startDate, endDate]);

  if (!isOpen) return null;

  return (
    <div className="date-modal">
      <div className="date-modal-content">
        <ul>
          <li onClick={setToday}>Today</li>
          <li onClick={setWeek}>This Week</li>
          <li onClick={setMonth}>This Month</li>
          <li onClick={setYear}>This Year</li>
          <CustomDatePicker
            startDate={startDate}
            endDate={endDate} onChange={handleDateChange}
            onApply={handleDateApply}
            onClear={handleDatePickerClear}
            onClose={handleDatePickerClose}
            dateRef={dateRef} />
        </ul>

      </div>
    </div>
  );
};

export default TCCDateModal;
