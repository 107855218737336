import { useState } from "react";
import cancelIcon from "../../assets/cancelIcon.svg";

const RetentionRate = ({ isOpen, onClose }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 2;

  const sampleData = [
    {
      email: "username@mail.com",
      last_act_date: "25-07-2024",
      session: "30 minutes",
      role: "Region JAMBI 1",
    },
    {
      email: "username@mail.com",
      last_act_date: "25-07-2024",
      session: "25 minutes",
      role: "Region JAMBI 2",
    },
    {
      email: "username@mail.com",
      last_act_date: "25-07-2024",
      session: "20 minutes",
      role: "PSM 3",
    }
  ]

  if (!isOpen) return null;

  const handleNextPage = () => {
    //HIT
    console.log('next');
  }

  const handlePrevPage = () => {
    //HIT
    console.log('prev');
  }

  return (
    <div className={`active-user ${isOpen ? "open" : ""}`}>
      <div className="active-user-content">
        <div className="active-user-header">
          <h1 className="active-user-title">Retained Users</h1>
          <button className="active-user-close-button" onClick={onClose}>
            <img src={cancelIcon} alt="cancel icon" />
          </button>
        </div>
        <div className="rating-separator" />
        <div className="feedback-section">
          <table className="feedback-table">
            <thead>
              <tr>
                <th>Email</th>
                <th>Last Activity Date</th>
                <th>Avg. Duration/Session</th>
                <th>Role</th>
              </tr>
            </thead>
            <tbody>
              {sampleData.map((value, index) => (
                <tr key={index}>
                  <td>{value.email}</td>
                  <td>{value.last_act_date}</td>
                  <td>{value.session}</td>
                  <td>{value.role}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="user-rating-pagination">
            <button onClick={handlePrevPage} disabled={currentPage === 1}>Previous</button>
            <button onClick={handleNextPage} disabled={currentPage === Math.ceil(sampleData.length / rowsPerPage)}>Next</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RetentionRate;