import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import kebabMenu from "../assets/kebabMenu.svg";
import { ReactComponent as DetailsIcon } from "../assets/detailsIcon.svg";
import { ReactComponent as PlusIcon } from "../assets/plusIcon.svg";
import { ReactComponent as RightArrow } from "../assets/rightArrow.svg";
import Pagination from "./Pagination";
import SampleQuestionModal from "./SampleQuestionModal";
import { sortIconAsc, sortIconDesc } from "../assets";
import loadingIcon from "../assets/loadingIcon.svg";
import StatusBox from "./StatusBox";
import { format } from "date-fns";

const Overview = ({ startDate, endDate, filters, currentPage, setCurrentPage }) => {
  const [data, setData] = useState([]);
  const [activeMenu, setActiveMenu] = useState(null);
  const [isSQModalOpen, setIsSQModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [sortOrder, setSortOrder] = useState("desc");
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const itemsPerPage = 10;

  const navigate = useNavigate();

  const limitTry = useRef(1);
  const currentTry = useRef(0);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const formattedStartDate = startDate
        ? format(startDate, "yyyy-MM-dd")
        : null;
      const formattedEndDate = endDate ? format(endDate, "yyyy-MM-dd") : null;

      const response = await fetch(
        `https://genai-cms-api.nawatech.co/api/sma/fallback/get-data-gpt?capability=${filters.detectedCapability}&suggested_capability=${filters.selectedCapability}&start_time=${formattedStartDate}&end_time=${formattedEndDate}&page=${currentPage}&size=${itemsPerPage}&sort=${sortOrder}&xis_export=false`
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();

      setData(result.data.results || []);
      setTotalPages(result.data.total_pages || 1);
    } catch (error) {
      console.error("Error fetching data:", error);
      if (currentTry.current < limitTry.current) {
        currentTry.current = currentTry.current + 1;
        fetchData();
      }
    }
    setIsLoading(false);
  };


  useEffect(() => {
    fetchData();
  }, [
    startDate,
    endDate,
    filters,
    currentPage,  
    itemsPerPage,
    sortOrder,
    isSQModalOpen,
  ]);

  const handleMenuClick = (index) => {
    setActiveMenu(activeMenu === index ? null : index);
  };

  const handleOptionClick = async (option, item) => {
    setActiveMenu(null);

    if (option === "Add as Sample Question") {
      setSelectedItem(item);
      setIsSQModalOpen(true);
    }

    if (option === "View Details") {
      try {
        const response = await fetch(
          `https://genai-cms-api.nawatech.co/api/sma/fallback/get-data-gpt-detail/${item.id}`
        );

        if (!response.ok) {
          throw new Error("Failed to get details data");
        }

        const detailsData = await response.json();
        navigate("/fallback-management/details", {
          state: { data: detailsData },
        });
      } catch (error) {
        console.error("Error fetching details data:", error);
      }
    }

    if (option === "View Function Detail") {
      navigate("/prompt-management/functions/details", {
        state: { id: item.selected_capability_id },
      });
    }
  };

  const handleSortClick = () => {
    setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const convertFormatedDate = (date) => {
    const newDate = new Date(date).toLocaleString('en-US', {
      hourCycle: "h24",
    });
    return newDate
      .replace('/', '-')
      .replace('/', '-');
  }

  return (
    <div className="overview-container" style={{ "width": "100%" }}>
      <div className="overview">
        <table>
          <thead>
            <tr>
              <th>Questions</th>
              <th>Bot Answer</th>
              <th>
                <div className="group-flex-8 align-center">
                  <span>Timestamp</span>
                  <img
                    src={
                      sortOrder === "desc"
                        ? sortIconAsc
                        : sortIconDesc
                    }
                    className="sort-icon"
                    alt="Sort icon"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleSortClick()}
                  />
                </div>
              </th>
              <th>Duration</th>
              <th>Status Code</th>
              <th>Detected Capability</th>
              <th>Selected Capability</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan={8} className="text-center">
                  <img
                    src={loadingIcon}
                    alt="Loading..."
                  />
                </td>
              </tr>
            ) : <> {data.length < 1 ? <td colSpan="8" className="text-center">No history data available</td> : data.map((item, index) => (
              <tr key={item.id}>
                <td>
                  {item.question.length > 100
                    ? item.question.slice(0, 100) + "..."
                    : item.question}
                </td>
                <td>
                  {item.message_text.length > 100
                    ? item.message_text.slice(0, 100) + "..."
                    : item.message_text}
                </td>
                <td>{convertFormatedDate(item.message_timestamp)}</td>
                <td>{item.response_duration}</td>
                <td>
                  <StatusBox status={item.response_status_code} />
                </td>
                <td>{item.detected_capability}</td>
                <td>
                  {item.selected_capability ? item.selected_capability : "null"}
                </td>
                <td style={{ position: "relative" }}>
                  <img
                    src={kebabMenu}
                    alt="kebab menu"
                    onClick={() => handleMenuClick(index)}
                    style={{ cursor: "pointer" }}
                  />
                  {activeMenu === index && (
                    <div className="menu">
                      <div
                        className="menu-option"
                        onClick={() => handleOptionClick("View Details", item)}
                      >
                        <DetailsIcon className="menu-icon" />
                        View Details
                      </div>
                      {item.selected_capability ||
                        item.selected_capability === "null" ? (
                        <div
                          className="menu-option"
                          onClick={() =>
                            handleOptionClick("View Function Detail", item)
                          }
                          style={{ cursor: "pointer" }}
                        >
                          <RightArrow className="menu-icon" />
                          View Function Detail
                        </div>
                      ) : (
                        <div
                          className="menu-option"
                          onClick={() =>
                            handleOptionClick("Add as Sample Question", item)
                          }
                          style={{ cursor: "pointer" }}
                        >
                          <PlusIcon className="menu-icon" />
                          Add as sample question
                        </div>
                      )}
                    </div>
                  )}
                </td>
              </tr>
            ))}
            </>
            }
          </tbody>
        </table>
        <Pagination
          totalPages={totalPages}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
        />
      </div>

      {selectedItem && (
        <SampleQuestionModal
          item={selectedItem}
          isOpen={isSQModalOpen}
          onClose={() => setIsSQModalOpen(false)}
        />
      )}

    </div>
  );
};

export default Overview;
